import { Component } from '@angular/core';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Step } from '@shared/models/step.model';
import { Router } from '@angular/router';
import { NavigateService } from '@core/routes/services/navigate.service';
import { AbstractListFiltersComponent } from '@shared/abstracts/list-filters.component.abstract';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-tactics-list-sub-header',
  templateUrl: './tactics-list-sub-header.component.html',
  styleUrls: ['./tactics-list-sub-header.component.scss'],
})
export class TacticsListSubHeaderComponent extends AbstractListFiltersComponent {
  get form(): UntypedFormGroup {
    return this.filtersService.filterFormGroup;
  }

  constructor(
    tacticsListService: TacticsListService,
    router: Router,
    public filtersService: TacticsFiltersService,
    public n: NavigateService,
  ) {
    super(router);
    this.listService = tacticsListService;
  }

  protected checkFilters(): void {
    this.checkChosenCategories();
    this.checkChosenSteps();
    this.checkIsPaid();
    this.checkPurchased();
    this.checkChosenPro();
  }

  private checkChosenCategories() {
    const chosen: IFilter<any>[] = [];
    this.filtersService.categories.map((category: IFilter<any>) => {
      this.filtersService.initCategoriesIds.indexOf(category.id) !== -1 ? chosen.push(category) : '';
    });
    this.filtersService.initCategoriesIds = [];
    chosen.length
      ? this.filtersService.filterFormGroup.get('categories')?.patchValue(chosen, { emitEvent: false })
      : '';
  }

  private checkChosenSteps() {
    const chosen: IFilter<Step>[] = this.filtersService.steps.filter(
      (step: IFilter<Step>) => this.filtersService.initStepsIds.indexOf(step.id) !== -1,
    );
    this.filtersService.initStepsIds = [];
    chosen.length ? this.filtersService.filterFormGroup.get('step')?.patchValue(chosen, { emitEvent: false }) : '';
  }

  private checkIsPaid() {
    if (this.filtersService.initPaid !== null) {
      this.filtersService.filterFormGroup
        .get('isPaid')
        ?.patchValue(this.filtersService.paidFilters.find((fil) => fil.value === this.filtersService.initPaid)!, {
          emitEvent: false,
        });
      this.filtersService.initPaid = null;
    }
  }

  private checkPurchased() {
    this.filtersService.filterFormGroup
      .get('purchased')
      ?.patchValue(
        this.filtersService.purchasedFilters.find((fil) => fil.value === this.filtersService.initPurchased) ?? null,
        { emitEvent: false },
      );
    this.filtersService.initPurchased = null;
  }

  private checkChosenPro() {
    if (this.filtersService.initPro !== null) {
      this.filtersService.filterFormGroup
        .get('pro')
        ?.patchValue(this.filtersService.proFilters.filter((fil) => fil.value === this.filtersService.initPro)!, {
          emitEvent: false,
        });
      this.filtersService.initPro = null;
    }
  }

  isFavouriteShowing() {
    return this.router.url.startsWith(this.n.getPath('tactics/list/favourite'));
  }
}
