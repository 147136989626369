import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { swiperFunnelConfig } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/funnel-manage-content/swiper-funnel-config.const';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { Tactic } from '@shared/models/tactic.model';
import { Step } from '@shared/models/step.model';
import { UserService } from '@shared/services/user.service';
import { SwiperComponent } from 'swiper/angular';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { FunnelInputGraphql, PermissionType, TacticRecommendationOutputGraphql } from '@modules/graphql/graphql-types';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { emptyRecommendations } from '@modules/statement/pages/statement-step-recomendations/components/statement-tactics/empty-recommendations.const';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Component({
  selector: 'df-statement-tactics',
  templateUrl: './statement-tactics.component.html',
  styleUrls: ['./statement-tactics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementTacticsComponent implements OnInit {
  @Output() emitTacticSet = new EventEmitter<Tactic[]>();
  @Output() numberOfRecommendedTactics = new EventEmitter<number>();

  swiperFunnelConfig = swiperFunnelConfig;
  funnelSteps: Step[] = [];
  recommendedTactics: Tactic[] = [];
  newFunnel!: Funnel;

  addedTactics: Tactic[] = [];

  emptyRecommendations: TacticRecommendationOutputGraphql[] = emptyRecommendations();

  @ViewChild('swiperComponent') swiperComponent!: SwiperComponent;

  constructor(
    private statementGraphqlService: StatementGraphqlService,
    private statementService: StatementService,
    public userService: UserService,
    private s: SnackbarService,
    private changes: ChangeDetectorRef,
    public funnelGraphqlService: FunnelGraphqlService,
    private eventService: EventsService,
  ) {}

  ngOnInit(): void {
    this.checkAccess();
  }

  checkAccess(): void {
    const user = this.userService.User!;
    if (user.hasAccess(PermissionType.TacticsRecommendations)) {
      if (!user.contextFunnel) {
        this.createFunnelForRecommendedTactics();
      } else {
        this.getRecommendedTactics();
      }
    } else {
      this.statementService.missingPermission = PermissionType.TacticsRecommendations;
      this._parseRecommendations(this.emptyRecommendations);
    }
  }

  getRecommendedTactics() {
    const numberOfRecords = 4;
    this.statementGraphqlService
      .getRecommendedTactics(this.userService.User?.contextFunnel.id!, numberOfRecords)
      .subscribe((res) =>
        this._parseRecommendations(res.data.getTacticsRecommendations as TacticRecommendationOutputGraphql[]),
      );
  }

  addOrDeleteTactic(tactic: Tactic) {
    const addedOrRemovedTactic = this.addedTactics.findIndex(
      (tac) => tac.id === tactic.id && tac.stepId === tactic.stepId,
    );
    if (addedOrRemovedTactic === -1) {
      this.addedTactics.push(tactic);
    } else {
      this.addedTactics.splice(addedOrRemovedTactic, 1);
    }
    this.emitTacticSet.emit(this.addedTactics);
  }

  createFunnelForRecommendedTactics() {
    const newFunnelData: FunnelInputGraphql = {
      name: 'Default Funnel',
      projectName: '',
    };
    this.funnelGraphqlService.createFunnel(newFunnelData).subscribe((res) => {
      this.newFunnel = res.data?.createFunnel as Funnel;
      this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelAdd, {
        userID: this.userService.User?.id,
      });

      if (this.newFunnel.id === this.newFunnel.owner.firstFunnel?.id) {
        this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelNew, {
          userID: this.userService.User?.id,
        });
      }
      const user = this.userService.User!;
      user.funnelsCount++;
      this.userService.User = user;
      this.userService.setContextFunnel(this.newFunnel)?.subscribe();
      this.getRecommendedTactics();
    });
  }

  private _parseRecommendations(recommendations: TacticRecommendationOutputGraphql[]): void {
    recommendations.forEach((elem) => {
      const newStep = new Step();
      newStep.id = elem.step.id;
      newStep.name = elem.step.name;
      newStep.type = elem.step.type;
      newStep.tactics = elem.recommendations.map((recommendation) => recommendation.tactic as Tactic);
      newStep.tactics.forEach((tactic) => {
        tactic.stepId = newStep.id;
      });
      this.funnelSteps.push(newStep);
      this.addedTactics.push(...newStep.tactics);
    });
    this.statementService.loadingTactics = false;
    this.changes.detectChanges();
    this.emitTacticSet.emit(this.addedTactics);
    this.numberOfRecommendedTactics.emit(this.addedTactics.length);
  }
}
