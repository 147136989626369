import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GenerateContentCostBadgeService } from './generate-content-cost-badge.service';

@Component({
  selector: 'df-generate-content-cost-badge',
  templateUrl: './generate-content-cost-badge.component.html',
  styleUrls: ['./generate-content-cost-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateContentCostBadgeComponent {
  constructor(readonly costBadgeService: GenerateContentCostBadgeService) {}
}
