import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StatementEventsService } from '@modules/statement/shared/services/statement-events.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-statement-welcome-on-board',
  templateUrl: './statement-welcome-on-board.component.html',
  styleUrls: ['./statement-welcome-on-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementWelcomeOnBoardComponent implements OnInit, OnDestroy {
  private hasAlreadySentTheEvent = false;

  constructor(
    private statementEventsService: StatementEventsService,
    private readonly statementService: StatementService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.document.querySelector('.new-statement-background')?.classList.add('mobile-no-bg-image');
  }

  ngOnInit(): void {
    this.statementService.stepNr.next(0);
  }

  public onSkipStatementEvent(): void {
    if (this.hasAlreadySentTheEvent) return;
    this.hasAlreadySentTheEvent = true;
    this.statementEventsService.sendSkipStatementEvent();
  }

  ngOnDestroy(): void {
    this.document.querySelector('.new-statement-background')?.classList.remove('mobile-no-bg-image');
  }
}
