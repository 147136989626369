<tui-island
  size="s"
  class="tui-accent-08 mt-6 border-primary"
>
  <h3 class="text-sm font-semibold mb-1">
    {{
      (errorMode
        ? 'Tactics.Manage.Tactic on market error'
        : 'Tactics.Manage.Fill following fields to sell tactic on market'
      ) | translate
    }}
  </h3>
  <ng-container *ngFor="let condition of conditions">
    <ng-container *ngIf="paidTacticValidationService.tacticValidationForm.get(condition) as conditionControl">
      <div
        class="flex items-center text-sm leading-normal mt-2"
        *ngIf="conditionControl.enabled && (!errorMode || conditionControl.invalid)"
      >
        <svg-icon
          class="mr-2"
          [src]="conditionControl.invalid ? 'assets/img/invalid.svg' : 'assets/img/valid.svg'"
        ></svg-icon>
        <p>{{ 'Tactics.Manage.' + condition | translate }}</p>
      </div>
    </ng-container>
  </ng-container>
</tui-island>
