<df-full-list-loader *ngIf="loading"></df-full-list-loader>
<div
  data-test="tactics"
  class="w-full h-full overflow-y-auto py-5 px-4 md:px-6 lg:px-7.5 tui-base-02"
>
  <h3 class="text-base md:text-xl font-semibold tui-text-03 mb-4 mt-0">
    {{ 'Funnels.recommended tactics' | translate }}
  </h3>

  <df-funnel-recommendations-empty
    [access]="recommendationsAccess"
    *ngIf="(!statementService.recommendations || !statementService.recommendations?.length) && !loading"
  >
  </df-funnel-recommendations-empty>

  <df-funnel-recommendations-section
    *ngIf="recommendationsAccess && statementService.recommendations && statementService.recommendations.length > 0"
    [title]="'Funnels.Recommendations.Statement' | translate"
    [recommendations]="statementService.recommendations"
    (addSelectedToFunnel)="addRecommendationsToFunnel($event)"
  ></df-funnel-recommendations-section>
</div>
