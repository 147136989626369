import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { UserService } from '@shared/services/user.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Funnel } from '@shared/models/funnel.model';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { FunnelInputGraphql, PermissionType } from '@modules/graphql/graphql-types';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { StatementStepRecommendationsComponent } from '@modules/statement/pages/statement-step-recomendations/statement-step-recommendations.component';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { switchMap, tap } from 'rxjs/operators';
import { EventsService, GoogleAnalyticsEvent } from '@shared/services/events.service';

@Component({
  selector: 'df-create-new-context-funnel-dialog',
  templateUrl: './create-new-context-funnel-dialog.component.html',
  styleUrls: ['./create-new-context-funnel-dialog.component.scss'],
})
export class CreateNewContextFunnelDialogComponent {
  loading = false;
  creatingAndAddingTactics = false;
  private readonly newFunnelData: FunnelInputGraphql = {
    name: 'New Funnel',
    projectName: '',
  };

  get statementStepRecommendationsComponent(): StatementStepRecommendationsComponent | undefined {
    return this.context.data?.statementStepRecommendationsComponent;
  }

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<
      {
        data: {
          statementStepRecommendationsComponent: StatementStepRecommendationsComponent;
        };
      },
      boolean
    >,
    public funnelGraphqlService: FunnelGraphqlService,
    public userService: UserService,
    public s: SnackbarService,
    public t: TranslateService,
    private changes: ChangeDetectorRef,
    private pricingService: PricingService,
    private eventService: EventsService,
  ) {}

  addTacticsToFunnel() {
    this.loading = true;
    this.statementStepRecommendationsComponent?.addTacticsToFunnel().subscribe(
      () => this.context.completeWith(true),
      () => {
        this.loading = false;
        this.changes.detectChanges();
      },
    );
  }

  createFunnelAndAddTactics() {
    this.creatingAndAddingTactics = true;
    let permitted = false;
    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User!.funnelsCount + 1)
      .pipe(
        tap({
          next: (access) => (permitted = access),
          complete: () => (!permitted ? this.context.completeWith(true) : null),
        }),
        switchMap(() => this.funnelGraphqlService.createFunnel(this.newFunnelData)),
      )
      .subscribe({
        next: (res) => {
          const funnel: Funnel | undefined = res.data?.createFunnel as Funnel;
          if (funnel) {
            this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelAdd, {
              userID: this.userService.User?.id,
            });

            if (funnel.id === funnel.owner.firstFunnel?.id) {
              this.eventService.pushToGoogleAnalyticsEvent(GoogleAnalyticsEvent.FunnelNew, {
                userID: this.userService.User?.id,
              });
            }
            const user = this.userService.User!;
            user.funnelsCount += 1;
            this.userService.User = user;
            this.userService
              .setContextFunnel(funnel)
              ?.toPromise()
              ?.then(() => this.addTacticsToFunnel());
          } else if (res.errors?.length === 0) {
            this.context.completeWith(true);
          }
        },
        error: () => {
          this.context.completeWith(false);
        },
      });
  }
}
