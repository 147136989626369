import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationTypeEnum, PayedTacticInputGraphql, TacticOutputGraphql } from '@modules/graphql/graphql-types';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { SetTacticAsPayedMutation } from '@modules/tactics/shared/graphql/mutations/set-tactic-as-payed.mutation.generated';
import { UserService } from '@shared/services/user.service';
import { NotificationsService } from '@modules/notifications/shared/services/notifications.service';
import { Config } from '@shared/configs/config';

@Injectable()
export class PutTacticOnMarketplaceService {
  form: UntypedFormGroup = new UntypedFormGroup({
    [EPutTacticOnMarketplaceForm.tacticId]: new UntypedFormControl(null, [Validators.required]),
    [EPutTacticOnMarketplaceForm.price]: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(Config.MIN_STRIPE_PRICE),
      Validators.max(Config.MAX_STRIPE_PRICE),
    ]),
    [EPutTacticOnMarketplaceForm.copyForSale]: new UntypedFormControl(null, [Validators.maxLength(150)]),
  });

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  constructor(
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    private userService: UserService,
    private notificationsService: NotificationsService,
  ) {}

  save(): Observable<TacticOutputGraphql | undefined> {
    this._loading$.next(true);
    return this.tacticGraphqlService.setTacticAsPayed(this._getFormValue()).pipe(
      map((res: FetchResult<SetTacticAsPayedMutation>) => res.data?.setTacticAsPayed as TacticOutputGraphql),
      tap(() => {
        const user = this.userService.User!;
        user.paidTacticCount++;
        this.userService.User = user;
        this.s.success(this.t.instant('Tactics.Manage.Tactic successfully put on marketplace. Congrats!'));
        if (user.skills.length === 0) {
          this.notificationsService.showNotification(null, NotificationTypeEnum.FillOutProfile);
        }
      }),
      catchError((err) => {
        this.s.error(this.t.instant('Tactics.Manage.Error during putting tactic on marketplace. Try again.'));
        return throwError(err);
      }),
      finalize(() => this._loading$.next(false)),
    );
  }

  private _getFormValue(): PayedTacticInputGraphql {
    const value: PayedTacticInputGraphql = { ...this.form.value };
    value[EPutTacticOnMarketplaceForm.price] = Math.round(value[EPutTacticOnMarketplaceForm.price] * 100);
    if (!value[EPutTacticOnMarketplaceForm.copyForSale]) {
      delete value[EPutTacticOnMarketplaceForm.copyForSale];
    }
    return value;
  }
}

export enum EPutTacticOnMarketplaceForm {
  tacticId = 'id',
  price = 'price',
  copyForSale = 'copyForSale',
}
