import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'df-icon-tile',
  templateUrl: './icon-tile.component.html',
  styleUrls: ['./icon-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTileComponent {
  @Input() checked = false;

  @Input() name?: string = '';
  @Input() icon = '';
  @Input() checkbox?: boolean = false;
  @Output() choose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('click') onClick() {
    this.checked = !this.checked;
    this.choose.emit(this.checked);
  }

  @HostBinding('class.checked')
  get isChecked() {
    return this.checked && this.checkbox;
  }

  constructor(private changes: ChangeDetectorRef) {}

  check() {
    this.checked = true;
    this.changes.detectChanges();
  }
}
