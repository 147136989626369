import { TacticRecommendationOutputGraphql } from '@modules/graphql/graphql-types';

export const emptyRecommendations = (): TacticRecommendationOutputGraphql[] => {
  return [
    {
      recommendations: [],
      step: {
        id: 1,
        name: 'Icebreaker',
        type: 'icebreaker',
        tactics: [],
      },
    },
    {
      recommendations: [],
      step: {
        id: 2,
        name: 'Cheeseburger',
        type: 'cheeseburger',
        tactics: [],
      },
    },
    {
      recommendations: [],
      step: {
        id: 3,
        name: 'Main Product',
        type: 'main-product',
        tactics: [],
      },
    },
    {
      recommendations: [],
      step: {
        id: 4,
        name: 'Upsell',
        type: 'upsell',
        tactics: [],
      },
    },
  ];
};
