import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { IVerticalTab } from '@shared/modules/ui/components/vertical-tabs/vertical-tab.interface';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { IFilter } from '@shared/interfaces/filter.interface';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { Subscription } from 'rxjs';
import { tacticsListTabsConst } from '@modules/tactics/modules/tactics-list/shared/components/tactics-list-header/tactics-list-tabs.const';
import { Step } from '@shared/models/step.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BasePanelService } from '@modules/base-panel/pages/base-panel/services/base-panel.service';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';

@Component({
  selector: 'df-tactics-list-sidebar',
  templateUrl: './tactics-list-sidebar.component.html',
  styleUrls: ['./tactics-list-sidebar.component.scss'],
})
export class TacticsListSidebarComponent implements OnInit, OnDestroy {
  tabs: IVerticalTab[] = [];
  dummyFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription = new Subscription();
  mobileOpened = false;
  filtersTimeout!: NodeJS.Timeout;

  @HostBinding('class.mobile-opened')
  get isMobileOpened() {
    return this.mobileOpened;
  }

  @HostBinding('class.modal-list')
  get isModalList() {
    return this.modalList;
  }

  @Input() modalList = false;
  @Input() step: Step | null = null;

  readonly stringify: TuiStringHandler<
    | { sortField: string; sortDirection: string; name: string }
    | TuiContextWithImplicit<{
        sortField: string;
        sortDirection: string;
        name: string;
      }>
  > = (item) => ('name' in item ? item.name : item.$implicit.name);

  constructor(
    public t: TranslateService,
    public n: NavigateService,
    public tacticsFiltersService: TacticsFiltersService,
    public tacticsListService: TacticsListService,
    private changes: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public basePanelService: BasePanelService,
  ) {}

  ngOnInit(): void {
    this.tabs = tacticsListTabsConst(this.n, this.t, this.modalList);
    this.listenReadUrlParams();
    this.listenChangeRoute();
    this.listenFilterLoaded();
    this.listenListChanges();
  }

  listenListChanges() {
    const sub = this.tacticsListService.emitter.subscribe((e: ListEvent) => {
      switch (e.type) {
        case ListEventType.END_GETTING_RECORDS:
          this.setDisableCheckbox(false);
          break;
      }
    });
    this.sub.add(sub);
  }

  listenFilterLoaded() {
    const sub = this.tacticsFiltersService.$filtersLoaded.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenChangeRoute() {
    this.changes.detectChanges();
    const sub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.closeMobile();
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  onTabChange(tab: IVerticalTab) {
    this.tacticsFiltersService.filterFormGroup.get('type')?.setValue(tab.type);
    this.changes.detectChanges();
  }

  openMobile() {
    this.mobileOpened = true;
  }

  closeMobile() {
    this.mobileOpened = false;
  }

  listenReadUrlParams(): void {
    this.checkChosenSteps();
    const sub = this.tacticsListService.emitter.subscribe((event: ListEvent) => {
      if (event.type === ListEventType.READ_URL_PARAMS) {
        this.checkChosenSteps();
      }
    });
    this.sub.add(sub);
  }

  checkChosenSteps() {
    const chosen: IFilter<Step>[] = [];
    this.tacticsFiltersService.steps.map((step: IFilter<Step>) => {
      this.tacticsFiltersService.initStepsIds.indexOf(step.id) !== -1 ? chosen.push(step) : '';
      this.dummyFormGroup.addControl(
        'dummyStep-' + step.id,
        new UntypedFormControl(this.tacticsFiltersService.initStepsIds.indexOf(step.id) !== -1),
      );
    });
    this.tacticsFiltersService.initStepsIds = [];
    chosen.length
      ? this.tacticsFiltersService.filterFormGroup.get('step')?.patchValue(chosen, { emitEvent: false })
      : '';
    this.changes.detectChanges();
  }

  onSortChange() {
    this.closeMobile();
    this.changes.detectChanges();
  }

  onCheckboxChange(e, step: IFilter<Step>): void {
    this.filtersTimeout ? clearTimeout(this.filtersTimeout) : '';
    const checkArray: IFilter<Step>[] = this.tacticsFiltersService.filterFormGroup.get('step')
      ?.value as IFilter<Step>[];
    e = e as { target: HTMLInputElement };
    if (e.target.checked) {
      checkArray.push(step);
    } else {
      checkArray.forEach((item: IFilter<Step>, index: number) => {
        if (item.id === step.id) {
          checkArray.splice(index, 1);
        }
      });
    }
    this.closeMobile();
    this.filtersTimeout = setTimeout(() => {
      this.setDisableCheckbox(true);
      this.tacticsFiltersService.filterFormGroup.get('step')?.setValue(checkArray);
    }, 600);
  }

  setDisableCheckbox(value: boolean) {
    value ? this.dummyFormGroup.disable() : this.dummyFormGroup.enable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
