import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Tactic } from '@shared/models/tactic.model';
import { UserService } from '@shared/services/user.service';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Router } from '@angular/router';
import { TutorialService } from '@shared/services/tutorial-service.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { CreateNewContextFunnelDialogComponent } from '@modules/statement/pages/statement-step-recomendations/components/create-new-context-funnel-dialog/create-new-context-funnel-dialog.component';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { CreateManyFunnelTacticInputGraphql, PermissionType } from '@modules/graphql/graphql-types';
import { FetchResult } from '@apollo/client/core';
import { GlobalDataService } from '@shared/services/global-data.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { finalize, tap } from 'rxjs/operators';
import { CreateManyFunnelsTacticsMutation } from '@modules/tactics/shared/graphql/mutations/create-many-funnel-tactics.mutation.generated';

@Component({
  selector: 'df-statement-step-recomendation',
  templateUrl: './statement-step-recommendations.component.html',
  styleUrls: ['./statement-step-recommendations.component.scss'],
})
export class StatementStepRecommendationsComponent implements OnInit {
  readonly PermissionType = PermissionType;

  tactics: Tactic[] = [];
  numberOfTactics!: number;
  contextFunnelId!: number;
  loading = false;

  sub: Subscription = new Subscription();

  constructor(
    public statementService: StatementService,
    public statementGraphqlService: StatementGraphqlService,
    public userService: UserService,
    public tutorialService: TutorialService,
    public n: NavigateService,
    public t: TranslateService,
    public s: SnackbarService,
    public pricingService: PricingService,
    public globalData: GlobalDataService,
    private changes: ChangeDetectorRef,
    private router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.setStatementData();
  }

  setStatementData() {
    this.statementService.loadingTactics = true;
    this.statementService.stepNr.next(null);
  }

  loadTacticsToFunnel(tactics: Tactic[]) {
    this.tactics = tactics;
  }

  loadNumberOfTactics(amountOfRecommendedTactics: number) {
    this.numberOfTactics = amountOfRecommendedTactics;
  }

  openConfirmation() {
    const user = this.userService.User!;
    this.pricingService.checkPricing(PermissionType.FunnelManagement, user.funnelsCount).subscribe(() => {
      if (user.funnelsCount === 1 && user.contextFunnel?.tactics.length === 0) {
        this.addTacticsToFunnel().subscribe();
      } else {
        this.dialogService
          .open<number>(new PolymorpheusComponent(CreateNewContextFunnelDialogComponent, this.injector), {
            size: 'm',
            label: this.t.instant('Statement.Dialog confirmation'),
            data: {
              statementStepRecommendationsComponent: this,
            },
          })
          .subscribe();
      }
    });
  }

  addTacticsToFunnel(): Observable<FetchResult<CreateManyFunnelsTacticsMutation>[]> {
    this.loading = this.tactics.length > 0;
    const inputs: CreateManyFunnelTacticInputGraphql[] = this.tactics.map((tactic) => ({
      tacticId: tactic.id,
      stepId: tactic.stepId || 0,
      position: 0,
    }));

    const chunk = 10;
    const obs: Observable<FetchResult<CreateManyFunnelsTacticsMutation>>[] = [];
    for (let i = 0; i < inputs.length; i += chunk) {
      obs.push(
        this.statementGraphqlService.createManyFunnelsTactics(
          inputs.slice(i, i + chunk),
          this.userService.User!.contextFunnel.id!,
        ),
      );
    }

    return forkJoin(obs).pipe(
      tap(() => this.tutorialService.openTutorial(), this._errorHandler.bind(this)),
      finalize(() => {
        this.loading = false;
        this.changes.detectChanges();
      }),
    );
  }

  goToPackets(): void {
    this.pricingService.openPackets();
  }

  skipPackets(): void {
    this.tutorialService.openTutorial();
  }

  private _errorHandler(): void {
    this.s.error(this.t.instant('Statement.Something went wrong during adding recommended tactics. Please try again.'));
  }
}
