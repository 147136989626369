import { Component } from '@angular/core';
import { AbstractListFiltersComponent } from '@shared/abstracts/list-filters.component.abstract';
import { UntypedFormGroup } from '@angular/forms';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { Router } from '@angular/router';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-tactics-list-sort',
  templateUrl: './tactics-list-sort.component.html',
  styleUrls: ['./tactics-list-sort.component.scss'],
})
export class TacticsListSortComponent extends AbstractListFiltersComponent {
  get form(): UntypedFormGroup {
    return this.filtersService.filterFormGroup;
  }

  constructor(
    tacticsListService: TacticsListService,
    router: Router,
    public filtersService: TacticsFiltersService,
    public n: NavigateService,
  ) {
    super(router);
    this.listService = tacticsListService;
  }

  protected checkFilters(): void {}
}
