import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'df-recommendations-tactics-modal',
  templateUrl: './recommendations-tactics-modal.component.html',
  styleUrls: ['./recommendations-tactics-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsTacticsModalComponent implements OnInit {
  advantages: Array<string> = [];
  constructor(@Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialogContext<boolean>) {}

  ngOnInit(): void {
    this.advantages = [
      'Funnels.Recommendations.Dialogs.Find tactics faster',
      'Funnels.Recommendations.Dialogs.Improve funnel easily',
    ];
  }

  close(): void {
    // this.n.go();
    this.context.completeWith(false);
  }
}
