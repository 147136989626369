import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { Subscription } from 'rxjs';
import { Tactic } from '@shared/models/tactic.model';
import { TacticGraphqlService } from '@modules/tactics/shared/services/tactic-graphql.service';
import { FetchResult } from '@apollo/client/core';
import { GetKanbanQuery } from '@modules/tactics/shared/graphql/queries/get-kanban.query.generated';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Step } from '@shared/models/step.model';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { TacticModalListComponent } from '@modules/tactics/modules/tactics-list/pages/tactic-modal-list/tactic-modal-list.component';
import { swiperConfig } from '@modules/tactics/modules/tactics-list/pages/my-funnel/swiper.config';
import {
  TacticsListEvent,
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'df-my-funnel',
  templateUrl: './my-funnel.component.html',
  styleUrls: ['./my-funnel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyFunnelComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  steps: Step[] = [];
  swiperOptions: SwiperOptions = swiperConfig;
  loading = false;

  constructor(
    public tacticFiltersService: TacticsFiltersService,
    private tacticsListService: TacticsListService,
    private changes: ChangeDetectorRef,
    private tacticGraphqlService: TacticGraphqlService,
    private s: SnackbarService,
    private t: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.listenLoadFilters();
    this.listenListEvents();
    this.getTactics();
  }

  listenLoadFilters() {
    const sub = this.tacticFiltersService.$filtersLoaded.subscribe(() => {
      this.changes.detectChanges();
    });
    this.sub.add(sub);
  }

  listenListEvents() {
    const sub = this.tacticsListService.tacticsListEmitter.subscribe((e: TacticsListEvent) => {
      switch (e.type) {
        case TacticsListEventType.RELOAD_FUNNEL:
          this.getTactics(true);
          break;
      }
    });
    this.sub.add(sub);
  }

  getTactics(noLoading = false) {
    !noLoading ? (this.loading = true) : '';
    this.changes.detectChanges();
    this.tacticGraphqlService
      .getKanban()
      .subscribe({
        next: (res: FetchResult<GetKanbanQuery>) => {
          this.steps = [];
          this.changes.detectChanges();
          this.steps = res.data?.getKanban.map((step) => {
            step.tactics = step.tactics.map((t) => {
              return new Tactic(t);
            });
            return step;
          }) as Step[];
          this.changes.detectChanges();
        },
        error: () => {
          this.s.error(
            this.t.instant('Tactics.List.There is problem with getting tactics. Refresh your view and try again.'),
          );
        },
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  onRemoveFromFunnel(tactic: Tactic, step: Step) {
    this.steps = this.steps.map((s) => {
      if (s.id === step.id) {
        s.tactics = s.tactics.filter((t) => t.id !== tactic.id);
      }
      return s;
    });
    this.changes.detectChanges();
    this.getTactics(true);
  }

  openTacticsModalList(step: Step) {
    this.dialogService
      .open<number>(new PolymorpheusComponent(TacticModalListComponent, this.injector), {
        size: 'l',
        dismissible: true,
        closeable: true,
        data: {
          step: step,
        },
        label: this.t.instant('Tactics.List.Tactics for :step', {
          stepName: step.name,
        }),
      })
      .subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
