<h3 class="tui-text_h4 text-center w-full mb-6">
  {{ 'Pricing.start trial' | translate }}
</h3>
<p class="w-full tui-text-03 mb-10 text-center px-10">
  <span
    [innerHTML]="'Pricing.Are you sure you want to start free trial?' | translate: { planName: planToBuy?.name }"
  ></span>
  <br />
  <span
    class="tui-text-02 text-xs"
    [innerHTML]="'Pricing.You can use the trial...' | translate"
  ></span>
</p>
<div class="w-full flex justify-center items-center gap-y-2 gap-x-3">
  <button
    tuiButton
    appearance="outline"
    type="button"
    size="l"
    (click)="cancel()"
  >
    {{ 'cancel' | translate }}
  </button>
  <button
    tuiButton
    appearance="primary"
    type="button"
    size="l"
    (click)="confirm()"
  >
    {{ 'Pricing.Yes, start free trial' | translate }}
  </button>
</div>
