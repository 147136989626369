import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PricingService, PricingStreamEventType } from '@modules/pricing/shared/services/pricing.service';
import { UserService } from '@shared/services/user.service';
import { FetchResult } from '@apollo/client/core';
import { GenerateSubscriptionsManagementUrlMutation } from '@shared/graphql/mutations/generate-subscriptions-management-url.mutation.generated';
import { XPartnerService } from '@shared/services/x-partner.service';
import { AuthStorageService } from '@core/services/auth-storage.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Component({
  selector: 'df-pricing-panel',
  templateUrl: './pricing-panel.component.html',
  styleUrls: ['./pricing-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPanelComponent implements OnInit {
  urlToPayments: string | null = null;

  get noActivePlan(): boolean {
    return !this.userService.User?.hasActivePlan();
  }

  constructor(
    private pricingService: PricingService,
    public userService: UserService,
    private changes: ChangeDetectorRef,
    public xPartnerService: XPartnerService,
    private authService: AuthStorageService,
    private n: NavigateService,
  ) {}

  ngOnInit(): void {
    this.userService.generateSubscriptionManagementUrl().subscribe({
      next: (res: FetchResult<GenerateSubscriptionsManagementUrlMutation>) => {
        this.urlToPayments = res.data?.generateSubscriptionsManagementUrl.redirectUrl!;
        this.changes.detectChanges();
      },
    });
  }

  closePricingPanel() {
    this.pricingService.currentStream.emit({
      type: PricingStreamEventType.CANCEL_STREAM,
      access: false,
    });
  }

  goToPaymentSettings(): void {
    if (!this.urlToPayments) return;
    window.open(this.urlToPayments, '_self');
  }

  logout() {
    this.authService.logout();
    this.n.router
      .navigate([{ outlets: { pricing: null, statement: null } }], {
        skipLocationChange: true,
      })
      .then(() => this.n.go('sign-in'));
  }
}
