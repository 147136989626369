import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, Input } from '@angular/core';
import { PermissionType } from '@modules/graphql/graphql-types';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { PutTacticOnMarketplaceModalComponent } from '@modules/tactics/shared/components/put-tactic-on-marketplace-modal/put-tactic-on-marketplace-modal.component';
import { TuiDialogService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { UserService } from '@shared/services/user.service';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { Tactic } from '@shared/models/tactic.model';

@Component({
  selector: 'df-sell-on-marketplace-button',
  templateUrl: './sell-on-marketplace-button.component.html',
  styleUrls: ['./sell-on-marketplace-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellOnMarketplaceButtonComponent {
  @Input() tactic?: Tactic;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private t: TranslateService,
    public n: NavigateService,
    private changes: ChangeDetectorRef,
    public userService: UserService,
    private pricingService: PricingService,
  ) {}

  sellOnMarketplace(): void {
    this.pricingService.checkPricing(PermissionType.PublicTactics).subscribe(() => {
      this.dialogService
        .open<number>(new PolymorpheusComponent(PutTacticOnMarketplaceModalComponent, this.injector), {
          dismissible: true,
          closeable: true,
          size: 'm',
          label: this.t.instant('Tactics.Manage.Sell on marketplace'),
        })
        .subscribe(() => this.changes.detectChanges());
    });
  }
}
