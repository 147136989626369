<div
  class="m-6 h-40"
  *ngIf="stripeLoading$ | async"
>
  <df-page-list-loader></df-page-list-loader>
</div>
<div
  class="flex flex-col lg:px-8"
  *ngIf="(stripeLoading$ | async) === false"
>
  <tui-island
    class="bg-red-50 mb-6"
    size="s"
    *ngIf="(stripeVerified$ | async) === false"
  >
    <div class="flex justify-between items-center">
      <div>
        <h3 class="text-sm font-semibold mb-1 text-red-500">
          {{ 'Tactics.Manage.Before putting on market' | translate }}
        </h3>
        <p class="text-xs tui-text-05 text-red-400">
          {{ 'Tactics.Manage.Fill out payout data' | translate }}
        </p>
      </div>
      <button
        tuiButton
        appearance="outline"
        type="button"
        class="tui-accent-01 ml-4"
        (click)="goToPayoutSettings()"
      >
        {{ 'Tactics.Manage.Fill out now' | translate }}
      </button>
    </div>
  </tui-island>
  <tui-island
    class="tui-base-02"
    size="s"
  >
    <h3 class="text-sm font-semibold mb-1">
      {{ 'Tactics.Manage.Tactic visibility' | translate }}
    </h3>
    <p class="text-xs tui-text-05">
      {{ 'Tactics.Manage.Tactic will be set to public' | translate }}
    </p>
  </tui-island>
  <tui-island
    size="s"
    class="mt-6"
  >
    <form
      [formGroup]="form"
      [validate-form]="form"
    >
      <div class="tui-form__row">
        <p class="text-sm font-semibold mb-1">
          {{ 'Tactics.Manage.Tactic cost' | translate }}
        </p>
        <tui-input-number
          class="price-input"
          [formControlName]="EPutTacticOnMarketplaceForm.price"
          [tuiTextfieldLabelOutside]="true"
          [min]="Config.MIN_STRIPE_PRICE"
          [max]="Config.MAX_STRIPE_PRICE"
          [precision]="2"
          [tuiTextfieldCustomContent]="dollar"
        >
          {{ 'price' | translate }}
        </tui-input-number>
        <ng-template #dollar>
          <svg-icon
            [src]="'/assets/img/ic_dollar_alt.svg'"
            [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
          ></svg-icon>
        </ng-template>
        <df-error-input [control]="form.get(EPutTacticOnMarketplaceForm.price)"></df-error-input>
      </div>

      <div class="tui-form__row">
        <label class="tui-text-02 labels mb-2 flex items-center font-medium">
          {{ 'Tactics.Manage.Introduction' | translate }}
          <svg-icon
            class="ml-2"
            [src]="'/assets/img/ic-info.svg'"
            [tuiHint]="typeTooltip"
            [tuiHintDirection]="'right'"
          ></svg-icon>
          <ng-template #typeTooltip>
            <div [innerHTML]="'Tactics.Manage.Introduction tooltip' | translate"></div>
          </ng-template>
        </label>

        <tui-text-area
          [tuiTextfieldLabelOutside]="true"
          [formControlName]="EPutTacticOnMarketplaceForm.copyForSale"
          [expandable]="true"
        >
          {{ 'Tactics.Manage.Copyforsale' | translate }}
        </tui-text-area>
        <df-error-input [control]="form.get(EPutTacticOnMarketplaceForm.copyForSale)"></df-error-input>
      </div>
    </form>
  </tui-island>

  <df-paid-tactics-checks></df-paid-tactics-checks>

  <div
    class="flex flex-col-reverse sm:flex-row justify-end items-stretch sm:items-center mt-4 -mb-4 -ml-6 -mr-6 md:-ml-8 md:-mr-8 px-3 md:px-4 lg:px-8"
  >
    <button
      size="xl"
      tuiButton
      type="button"
      class="sm:mr-4"
      appearance="outline"
      [disabled]="!!(loading$ | async)"
      (click)="cancel()"
    >
      <svg-icon
        [src]="'/assets/img/ic-close.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'cancel' | translate }}
    </button>

    <button
      size="xl"
      class="mb-2 sm:mb-0"
      tuiButton
      type="submit"
      appearance="primary"
      [showLoader]="!!(loading$ | async)"
      [disabled]="
        !!(loading$ | async) ||
        form.invalid ||
        paidTacticValidationService.tacticValidationForm.invalid ||
        (stripeVerified$ | async) === false
      "
      (click)="save()"
    >
      <svg-icon
        [src]="'/assets/img/ic-check.svg'"
        class="mr-2"
      ></svg-icon>
      {{ 'confirm' | translate }}
    </button>
  </div>
</div>
