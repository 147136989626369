<div>
  <p class="max-w-3-4 mb-10 mx-auto text-center">
    {{ 'Statement.Dialog explanation' | translate }}
  </p>
  <div class="w-full flex flex-col-reverse items-center sm:justify-center sm:flex-row">
    <button
      tuiButton
      appearance="outline"
      size="l"
      class="sm:mr-8"
      [showLoader]="creatingAndAddingTactics"
      (click)="createFunnelAndAddTactics()"
    >
      {{ 'Statement.Create new funnel' | translate }}
    </button>
    <button
      tuiButton
      appearance="primary"
      size="l"
      [showLoader]="loading && !creatingAndAddingTactics"
      class="mb-4 sm:mb-0"
      (click)="addTacticsToFunnel()"
    >
      {{ 'Statement.Add to my context funnel' | translate }}
    </button>
  </div>
</div>
