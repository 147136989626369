<div class="w-full h-full flex flex-nowrap justify-start items-stretch">
  <div class="hidden lg:flex w-96 tui-support-26-bg justify-center items-center p-4">
    <img
      src="assets/img/illustration_coin.svg"
      class="w-auto max-w-90"
      alt="credits"
    />
  </div>
  <div class="flex flex-col justify-start items-stretch md:pl-10 md:py-12 md:pr-16 flex-grow">
    <h1 class="tui-text_h2 mt-0 mb-2 tui-text-03 font-semibold">
      {{ 'Pricing.Not enough credits' | translate }}
    </h1>
    <p class="tui-text_body-xl tui-text-05">
      {{ 'Pricing.Not enough credits to proceed this feature.' | translate }}
    </p>

    <h4 class="mt-16 tui-text-body-l font-semibold">
      {{ 'Pricing.How to receive credits:' | translate }}
    </h4>
    <div class="flex justify-start items-center border border-solid tui-support-27-border p-4 rounded-2xl mt-4">
      <div class="flex justify-center items-center cheeseburger-text-light p-3 tui-support-27-bg rounded-lg">
        <svg-icon
          [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
          src="/assets/img/_ic_check_circle.svg"
        ></svg-icon>
      </div>
      <p class="tui-text-body-l tui-text-03 font-semibold ml-3 whitespace-pre-line">
        {{
          'Pricing.Wait for the next month. You will receive :number credits'
            | translate: { number: (userService.User?.activePlans)![0].credits }
        }}
      </p>
    </div>
    <div class="flex justify-start items-center border border-solid tui-support-27-border p-4 rounded-2xl mt-2">
      <div class="flex justify-center items-center cheeseburger-text-light p-3 tui-support-27-bg rounded-lg">
        <svg-icon
          [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
          src="/assets/img/_ic_check_circle.svg"
        ></svg-icon>
      </div>
      <p class="tui-text-body-l tui-text-03 font-semibold ml-3 whitespace-pre-line">
        {{ 'Pricing.Upgrade your current plan' | translate }}
      </p>
    </div>

    <div class="rounded-xl py-4 px-5 tui-text-03 warning-bg mt-5">
      <h3 class="tui-text_body-m-6 font-semibold">
        {{ 'Pricing.No possibility to buy more credits at this moment' | translate }}
      </h3>
      <p class="tui-text-body-s-2 mt-2">
        {{ 'Pricing.We are growing platform that will work on in' | translate }}
      </p>
    </div>

    <div class="mt-16">
      <button
        tuiButton
        type="button"
        appearance="outline"
        size="l"
        (click)="close()"
      >
        <span class="tui-primary">{{ 'Pricing.Ok, got it!' | translate }}</span>
      </button>
      <button
        tuiButton
        type="button"
        appearance="primary"
        size="l"
        class="ml-8"
        (click)="goToPackets()"
      >
        {{ 'Pricing.View Pricing' | translate }}
      </button>
    </div>
  </div>
</div>
