import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { TuiSizeXL, TuiSizeXS, TuiSizeXXL } from '@taiga-ui/core';

@Component({
  selector: 'df-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit {
  @Input() class = '';
  @Input() text = '';
  @Input() size: TuiSizeXS | TuiSizeXXL | TuiSizeXL = 'xxl';
  @Input() avatarUrl = '';
  @Input() autoColor = false;
  @Input() rounded = false;
  @Input() showBadge: boolean | undefined = false;

  @HostBinding('class.mobileAvatarSmall')
  @Input()
  mobileAvatarSmall: boolean | undefined = false;

  ngOnInit() {
    this.class += ' i18n-ex';
  }
}
