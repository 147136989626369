import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-tutorial-card',
  templateUrl: './tutorial-card.component.html',
  styleUrls: ['./tutorial-card.component.scss'],
})
export class TutorialCardComponent {
  @Input() imageSrc = '';
  @Input() number = '';
  @Input() instruction = '';
  @Input() done = false;
}
