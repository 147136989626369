import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { filter, map, switchMap } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { GetSubscriptionsPlansQuery } from '@modules/pricing/shared/graphql/queries/get-subscriptions-plans.query.generated';
import { SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';
import { PricingGraphqlService } from '@modules/pricing/shared/services/pricing-graphql.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { CreateSubscriptionMutation } from '@modules/pricing/shared/graphql/mutations/create-subscription.mutation.generated';
import { UserService } from '@shared/services/user.service';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-start-trial-modal',
  templateUrl: './start-trial-modal.component.html',
  styleUrls: ['./start-trial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartTrialModalComponent {
  loading = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { createSubscription: CreateSubscriptionMutation } }, boolean>,
    private readonly pricingGraphqlService: PricingGraphqlService,
    private readonly changes: ChangeDetectorRef,
    private readonly s: SnackbarService,
    private readonly userService: UserService,
  ) {}

  cancel(): void {
    this.context.completeWith(false);
  }

  confirm(): void {
    this.loading = true;
    this.changes.detectChanges();
    this.pricingGraphqlService
      .getPackets()
      .pipe(
        map(
          (res: FetchResult<GetSubscriptionsPlansQuery>) =>
            res.data?.getSubscriptionsPlans as SubscriptionPlanOutputGraphql[],
        ),
        map(
          (plans: SubscriptionPlanOutputGraphql[]) =>
            plans.find((p) => p.name.includes(Config.DEFAULT_TRIAL_PLAN))?.id ?? -1,
        ),
        filter((proPlanId: number) => proPlanId >= 0),
        switchMap((proPlanId: number) => this.pricingGraphqlService.createSubscription(proPlanId, undefined, true)),
      )
      .subscribe(
        (res: FetchResult<CreateSubscriptionMutation>) => {
          this.userService.saveBeforePaymentUrl('/');
          this.userService.saveSelectedTrial(Config.DEFAULT_TRIAL_PLAN);
          window.location.href = res.data?.createSubscription.redirectUrl!;
        },
        () => {
          this.loading = false;
          this.changes.detectChanges();
          this.s.defaultError();
        },
      );
  }
}
