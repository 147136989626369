<df-statement-content-wrapper>
  <div class="container flex items-center justify-between">
    <div class="flex flex-col p-4 md:p-0 mt-0 md:mt-10">
      <div class="animate__animated animate__headShake">
        <svg-icon [src]="'/assets/img/statement-success.svg'"></svg-icon>
      </div>
      <h1
        class="tui-accent-07 title mb-4 mt-4"
        [innerHTML]="'Statement.Success! You are awesome!' | translate"
      ></h1>
      <h3 class="tui-text_h5 tui-text-03 max-w-lg">
        {{ 'Statement.Now you know strengths...' | translate }}
      </h3>
      <h5 class="tui-text_body-m -tui-text-03 mt-2">
        {{ 'Statement.You can edit statement in the settings!' | translate }}
      </h5>
      <div class="flex items-center justify-start mt-8">
        <button
          size="l"
          tuiButton
          type="submit"
          appearance="primary"
          (click)="onEnd()"
        >
          <span *ngIf="!statementService.startPreviousUrl">{{ 'Statement.Show the tactics!' | translate }}</span>
          <span *ngIf="statementService.startPreviousUrl">{{ 'Statement.Go to app' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="hidden lg:block">
      <svg-icon [src]="'/assets/img/statement-success-illustration.svg'"></svg-icon>
    </div>
  </div>
</df-statement-content-wrapper>
