import { StepOutputGraphql } from '@modules/graphql/graphql-types';
import { Tactic } from '@shared/models/tactic.model';

export class Step implements StepOutputGraphql {
  id!: number;
  name!: string;
  type!: string;
  tactics!: Tactic[];
  contextFunnelTacticCount?: number;
  loading?: boolean;
}
