import { Config } from '@shared/configs/config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogTallyRecStmtServiceService {
  Config = Config;
  statementParamType = '';
  tallyCustomUrl = '';

  setStatementParam(statementParamType: string) {
    this.statementParamType = statementParamType;
    this.tallyCustomUrl = Config.TALLY_RASP_URLS[this.statementParamType];
  }
}
