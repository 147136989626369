import { Component } from '@angular/core';

@Component({
  selector: 'df-tutorial-video',
  templateUrl: './tutorial-video.component.html',
  styleUrls: ['./tutorial-video.component.scss'],
})
export class TutorialVideoComponent {
  apiLoaded = false;
  width!: number;
  height!: number;
}
