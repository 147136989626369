<div class="pricing">
  <header class="pricing__header flex items-center justify-end sm:justify-between pl-9 pr-4 py-2">
    <svg-icon
      class="hidden sm:block pt-7"
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
    ></svg-icon>
    <div class="flex justify-end items-center">
      <ng-container *ngIf="!noActivePlan; else logoutButton">
        <button
          *ngIf="urlToPayments"
          tuiButton
          class="mr-2"
          type="button"
          appearance="outline"
          (click)="goToPaymentSettings()"
        >
          {{ 'Pricing.Manage your plan' | translate }}
        </button>

        <button
          tuiIconButton
          type="button"
          appearance="outline"
          [icon]="icon"
          (click)="closePricingPanel()"
        ></button>
        <ng-template #icon>
          <svg-icon [src]="'/assets/img/ic-close.svg'"></svg-icon>
        </ng-template>
      </ng-container>
      <ng-template #logoutButton>
        <button
          tuiButton
          class="mr-2"
          type="button"
          appearance="outline"
          (click)="logout()"
        >
          <svg-icon
            [src]="'/assets/img/turn-off.svg'"
            class="mr-2"
          ></svg-icon>
          {{ 'Header.logout' | translate }}
        </button>
      </ng-template>
    </div>
  </header>

  <div class="pricing__content">
    <router-outlet></router-outlet>
  </div>
</div>
5
