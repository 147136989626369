<df-full-list-loader *ngIf="loading"></df-full-list-loader>

<div class="pb-4 pt-4">
  <div
    *ngIf="items.length === 0"
    class="w-full h-full flex flex-col items-center justify-center"
  >
    <svg-icon
      [src]="'/assets/img/ic-no-records.svg'"
      [svgStyle]="{ width: '150px', height: '150px' }"
    ></svg-icon>
    <h5 class="tui-text_h5 mt-4">
      {{ 'Tiles.List.Empty' | translate }}
    </h5>
  </div>

  <div
    class="flex flex-wrap px-2 md:px-4 lg:px-8 gap-4"
    *ngIf="items.length > 0"
  >
    <a
      [routerLink]="[item.editUrl]"
      class="tiles-tile flex justify-between items-start overflow-hidden cursor-pointer"
      *ngFor="let item of items; let i = index"
    >
      <div class="flex flex-col justify-between items-start p-3 h-full w-full relative">
        <div class="to-hover flex flex-col items-start justify-start">
          <h2 class="tui-text-01 tui-text_body-17 line-clamp-5 break-words">
            {{ item.name }}
          </h2>
        </div>
        <div class="tiles-backdrop absolute w-full h-3/5 bottom-0 left-0 right-0 p-3 flex flex-col justify-between">
          <div
            class="w-full"
            *ngIf="item.description"
          >
            <span class="tui-text__body-s tui-text-02 mr-2 line-clamp-1 break-words">{{ item.description }}</span>
          </div>
          <div class="tiles-actions w-full flex flex-row items-center justify-end relative">
            <button
              tuiIconButton
              type="button"
              appearance="secondary"
              [icon]="icon"
              (click)="$event.stopPropagation()"
            ></button>
            <ng-template #icon>
              <svg-icon [src]="'/assets/img/ic-edit.svg'"></svg-icon>
            </ng-template>

            <button
              *ngIf="canDeleteItem"
              (click)="$event.stopPropagation(); $event.preventDefault()"
              mwlConfirmationPopover
              [popoverTitle]="'Tilte.List.Removing item' | translate"
              [popoverMessage]="'Tilte.List.Do you really want to remove this item' | translate"
              [appendToBody]="true"
              [closeOnOutsideClick]="true"
              placement="top"
              popoverClass="confirmation-popover"
              class="ml-2 tui-accent"
              tuiIconButton
              type="button"
              appearance="secondary"
              (confirm)="removeItem(item)"
              [icon]="iconTrash"
            ></button>
            <ng-template #iconTrash>
              <svg-icon [src]="'/assets/img/ic-trash.svg'"></svg-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
