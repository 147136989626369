<div class="sm:flex items-start justify-center">
  <swiper
    #swiperComponent
    [config]="swiperFunnelConfig"
    [spaceBetween]="0"
    class="pt-2 w-full swiper__container"
    [ngClass]="{ 'swiper__container--empty': !funnelSteps.length }"
  >
    <ng-template
      swiperSlide
      *ngFor="let step of funnelSteps"
    >
      <tui-island
        [size]="'s'"
        class="column flex flex-col items-start justify-start w-10/12 mx-auto mb-2 {{ step.type }}-faded"
      >
        <df-statement-tactics-step-header [step]="step"></df-statement-tactics-step-header>
        <div class="mt-4 pl-2 pr-2 w-full flex-1">
          <ng-container *ngFor="let tactic of step.tactics">
            <df-statement-tactic-card
              [step]="step"
              [tactic]="tactic"
              class="border shadow-lg {{ step.type }}"
              (tacticStatusChange)="addOrDeleteTactic($event)"
            >
            </df-statement-tactic-card>
          </ng-container>
        </div>
      </tui-island>
    </ng-template>
  </swiper>
</div>
