<df-statement-content-wrapper
  class="h-wrapper"
  data-test="welcome"
>
  <div class="container flex items-center justify-between">
    <div class="flex flex-col p-4 md:p-0 mb-10 mt-0 md:mt-10">
      <h1 class="h-header title leading-tight mt-0 sm:leading-normal items-center md:items-stretch flex md:flex-col">
        <div class="animate__animated animate__rotateIn md:mb-[0.67em] mr-4 md:mr-0">
          <svg-icon [src]="'/assets/img/statement-hello.svg'"></svg-icon>
        </div>
        {{ 'Statement.Welcome on board!' | translate }}
      </h1>
      <h3 class="h-subheader tui-text_h5 tui-text-03 max-w-3xl">
        {{ 'Statement.Welcome subtitle' | translate }}
      </h3>
      <h3 class="tui-text_h6 tui-text-05 font-light max-w-3xl mt-3">
        {{ 'Statement.Welcome skip onboarding' | translate }}
      </h3>
      <div class="flex items-center justify-center md:justify-start mt-8 btns-wrapper">
        <button
          size="l"
          tuiButton
          type="submit"
          appearance="primary"
          [routerLink]="['', { outlets: { statement: 'statement/2' } }]"
        >
          <svg-icon
            [src]="'/assets/img/ic-check.svg'"
            class="mr-2"
          ></svg-icon>
          {{ 'Yes, sure' | translate }}
        </button>

        <a
          data-test="skip-statement"
          (click)="onSkipStatementEvent()"
          size="l"
          tuiButton
          type="button"
          class="ml-4"
          appearance="outline"
          [routerLink]="['', { outlets: { statement: 'statement/6' } }]"
        >
          {{ 'Maybe later' | translate }}
        </a>
      </div>
    </div>
  </div>
</df-statement-content-wrapper>
