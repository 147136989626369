import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Item<T> {
  id: number;
  name: string;
  editUrl: string;
  [key: string]: string | number | boolean | T;
}

@Component({
  selector: 'df-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent<T> {
  @Input() items!: Item<T>[];
  @Input() loading = true;
  @Input() canDeleteItem = true;
  @Output() removeItemEvent = new EventEmitter<number>();

  removeItem(item) {
    this.removeItemEvent.emit(item.id);
  }
}
