<form
  [formGroup]="tacticsFiltersService.filterFormGroup"
  class="w-full h-full"
  [ngClass]="{ 'mobile-opened': mobileOpened }"
>
  <div
    class="flex flex-row w-full justify-start items-center mb-2 border-b border-gray-200 border-solid"
    *ngIf="modalList"
  >
    <svg-icon
      [src]="'/assets/img/ic-search.svg'"
      class="mr-4 cursor-pointer"
      (click)="input.nativeFocusableElement?.focus()"
    ></svg-icon>

    <tui-input-inline
      #input
      class="search-input w-full"
      [formControlName]="'search'"
    >
      {{ 'search' | translate }}
    </tui-input-inline>
  </div>

  <div
    class="flex items-center justify-between h-16 w-full"
    *ngIf="modalList"
  >
    <tui-select
      class="w-full"
      tuiTextfieldSize="l"
      (ngModelChange)="onSortChange()"
      [valueContent]="stringify"
      [formControlName]="'sort'"
    >
      {{ 'sort' | translate }}
      <tui-data-list *tuiDataList>
        <button
          tuiOption
          class="block"
          size="l"
          *ngFor="let item of tacticsFiltersService.sorts"
          [value]="item"
        >
          {{ item.name }}
        </button>
      </tui-data-list>
    </tui-select>
  </div>

  <form
    [formGroup]="dummyFormGroup"
    *ngIf="(!basePanelService.routeData?.shortLeftNavigation && !modalList) || modalList"
  >
    <h6 class="tui-text-04 uppercase tui-text_body-s mt-4">
      {{ 'Tactics.funnel step' | translate }}
    </h6>
    <ng-container *ngFor="let step of tacticsFiltersService.steps; let i = index">
      <tui-checkbox-labeled
        *ngIf="dummyFormGroup.get('dummyStep-' + step.id)"
        (change)="onCheckboxChange($event, step)"
        [formControlName]="'dummyStep-' + step.id"
        class="mt-5 mb-5 md:mt-3 md:mb-3"
        size="l"
      >
        <div class="flex items-center justify-start">
          <svg-icon
            [src]="'/assets/img/ic-' + step?.object?.type + '.svg'"
            class="mr-2"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
          <span class="tui-base-01">{{ step.name }}</span>
        </div>
      </tui-checkbox-labeled>
    </ng-container>
  </form>
</form>
