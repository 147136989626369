import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Step } from '@shared/models/step.model';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import {
  TacticsListEvent,
  TacticsListEventType,
  TacticsListService,
} from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { Subscription } from 'rxjs';
import { Funnel } from '@shared/models/funnel.model';

@Component({
  selector: 'df-tactic-modal-list',
  templateUrl: './tactic-modal-list.component.html',
  styleUrls: ['./tactic-modal-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticModalListComponent implements OnInit, OnDestroy {
  step: Step | null = null;
  toCopy = false;
  sub: Subscription = new Subscription();
  funnel?: Funnel;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { step: Step; toCopy: boolean; funnel: Funnel } }, boolean>,
    private changes: ChangeDetectorRef,
    private tacticFiltersService: TacticsFiltersService,
    private tacticsListService: TacticsListService,
  ) {}

  ngOnInit(): void {
    if (this.context?.data?.step) {
      this.step = this.context?.data?.step;
      this.changes.detectChanges();
    }
    if (this.context?.data?.toCopy) {
      this.toCopy = this.context?.data?.toCopy;
      this.changes.detectChanges();
    }
    if (this.context?.data?.funnel) {
      this.funnel = new Funnel(this.context?.data?.funnel);
      this.changes.detectChanges();
    }
    this.listenListEvents();
  }

  listenListEvents() {
    const sub = this.tacticsListService.tacticsListEmitter.subscribe((event: TacticsListEvent) => {
      switch (event.type) {
        case TacticsListEventType.CHOOSE_TO_COPY:
          this.context.completeWith(true);
          break;
      }
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.tacticFiltersService.resetFiltersForm();
    this.sub.unsubscribe();
  }
}
