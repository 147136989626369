<div>
  <tui-tabs
    class="tui-tabs-vertical"
    (activeItemIndexChange)="onIndexChange($event)"
  >
    <div
      class="vertical-underline"
      #verticalUnderline
    ></div>

    <ng-container *ngIf="isRouterLink">
      <a
        [routerLink]="tab.route"
        [routerLinkActive]="'vertical-active'"
        *ngFor="let tab of tabs"
        tuiTab
      >
        <svg-icon
          [src]="tab.icon"
          *ngIf="tab.icon"
          class="mr-4"
        ></svg-icon>
        {{ tab.name }}
      </a>
    </ng-container>

    <ng-container *ngIf="!isRouterLink">
      <button
        (click)="onTabChange.emit(tab)"
        *ngFor="let tab of tabs"
        tuiTab
      >
        <svg-icon
          [src]="tab.icon"
          *ngIf="tab.icon"
          class="mr-4"
        ></svg-icon>
        {{ tab.name }}
      </button>
    </ng-container>
  </tui-tabs>
</div>
