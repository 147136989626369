import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Tactic } from '@shared/models/tactic.model';
import { Step } from '@shared/models/step.model';

@Component({
  selector: 'df-statement-tactic-card',
  templateUrl: './statement-tactic-card.component.html',
  styleUrls: ['./statement-tactic-card.component.scss'],
})
export class StatementTacticCardComponent {
  @Input() tactic!: Tactic;
  @Input() step!: Step;
  @Output() tacticStatusChange = new EventEmitter<Tactic>();

  tacticFormControl: UntypedFormControl = new UntypedFormControl(true);

  watchTactics(tactic: Tactic, stepId: number) {
    tactic.stepId = stepId;
    this.tacticStatusChange.emit(tactic);
  }
}
