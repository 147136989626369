import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  EPutTacticOnMarketplaceForm,
  PutTacticOnMarketplaceService,
} from '@modules/tactics/shared/components/put-tactic-on-marketplace-modal/put-tactic-on-marketplace.service';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';
import { PaidTacticValidationService } from '@modules/tactics/modules/tactic-settings/shared/services/paid-tactic-validation.service';
import { MarketplaceStripeAccountService } from '@modules/authorization/shared/marketplace-stripe-account.service';
import { Config } from '@shared/configs/config';

// Component deprecated
// In  case of reusing TODO: change use of TacticManageService to context tactic variable
@Component({
  selector: 'df-put-tactic-on-marketplace-modal',
  templateUrl: './put-tactic-on-marketplace-modal.component.html',
  styleUrls: ['./put-tactic-on-marketplace-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PutTacticOnMarketplaceService],
})
export class PutTacticOnMarketplaceModalComponent implements OnInit {
  form: UntypedFormGroup;
  readonly EPutTacticOnMarketplaceForm: typeof EPutTacticOnMarketplaceForm = EPutTacticOnMarketplaceForm;
  readonly Config = Config;
  readonly loading$: Observable<boolean>;
  readonly stripeVerified$: Observable<boolean>;
  readonly stripeLoading$: Observable<boolean>;

  constructor(
    private readonly tacticMarketplaceService: PutTacticOnMarketplaceService,
    private readonly changes: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<any, boolean>,
    public readonly paidTacticValidationService: PaidTacticValidationService,
    private readonly marketplaceStripeAccountService: MarketplaceStripeAccountService,
  ) {
    this.form = this.tacticMarketplaceService.form;
    this.loading$ = this.tacticMarketplaceService.loading$;
    this.stripeVerified$ = this.marketplaceStripeAccountService.verified$;
    this.stripeLoading$ = this.marketplaceStripeAccountService.loading$;
  }

  ngOnInit(): void {
    this.marketplaceStripeAccountService.checkStripeAccount();
  }

  cancel(): void {
    this.context.completeWith();
  }

  save(): void {
    if (this.form.valid) {
      this.tacticMarketplaceService.save().subscribe(() => {
        this.context.completeWith();
      });
    } else {
      this.form.markAllAsTouched();
      this.changes.detectChanges();
    }
  }

  goToPayoutSettings(): void {
    window.location.href = Config.MARKETPLACE_URL + `/account/payments`;
  }
}
