import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceVariables',
})
export class ReplaceVariablesPipe implements PipeTransform {
  transform(value: string): unknown {
    return value.replace(/\${/g, '<span>').replace(/\}/g, '</span>');
  }
}
