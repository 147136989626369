<svg-icon
  [src]="'/assets/img/ic-search.svg'"
  [svgStyle]="{ width: '1rem', height: '1rem' }"
  class="mr-4 cursor-pointer"
  (click)="input.nativeFocusableElement?.focus()"
></svg-icon>

<tui-input-inline
  #input
  class="search-input w-full tui-text-02"
  [formControl]="search"
>
  {{ 'search' | translate }}
</tui-input-inline>
