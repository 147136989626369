<div class="banner w-full border border-solid tui-primary-border rounded-3xl tui-base-01-bg overflow-hidden">
  <div class="w-full h-full flex flex-col lg:flex-row justify-start items-start lg:items-center p-4 md:px-8 md:py-5">
    <div class="mr-6 hidden lg:flex tui-accent-13-bg rounded-full h-20 w-20 flex-shrink-0 justify-center items-center">
      <img
        src="assets/img/onboarding_cg_ai.svg"
        alt="text"
      />
    </div>
    <div class="lg:flex-grow mb-4 lg:mb-0">
      <h3 class="banner__header font-semibold mb-1">
        {{ 'Funnels.Onboarding.This text is generated by AI' | translate }}
      </h3>
      <p class="text-xs md:text-base">
        {{ 'Funnels.Onboarding.Provide your own copy to generate the AI enhanced text' | translate }}
      </p>
    </div>
    <button
      tuiButton
      type="button"
      size="xl"
      appearance="primary"
      class="onboarding-action banner__button"
    >
      {{ 'Funnels.Onboarding.Try it now' | translate }}
    </button>
  </div>
</div>
