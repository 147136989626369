import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Config } from '@shared/configs/config';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { XPartnerService } from '@shared/services/x-partner.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-statement-panel',
  templateUrl: './statement-panel.component.html',
  styleUrls: ['./statement-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementPanelComponent extends AbstractSubscriptionComponent implements OnInit {
  Config = Config;
  recommendations = false;
  isPublic: boolean;
  stepNr: number | null = null;
  @ViewChild('publicStatementContainer') publicStatementContainer!: ElementRef;

  constructor(
    public statementService: StatementService,
    public userService: UserService,
    private renderer: Renderer2,
    private router: Router,
    private changes: ChangeDetectorRef,
    public xPartnerService: XPartnerService,
  ) {
    super();
    this.renderer.removeClass(document.body, 'recaptcha');
    this.isPublic = this.statementService.isPublic;
  }

  ngOnInit(): void {
    this.statementService.readPreviousUrl();
    this._listenAllowSkip();
    this.setStepNr();
    this.listingForScrollTop();
  }

  setStepNr() {
    this.sub.add(this.statementService.stepNr.subscribe((v) => (this.stepNr = v)));
  }

  private listingForScrollTop(): void {
    this.sub.add(this.statementService.statementContainerScrollTop$.subscribe(() => this.scrollContainerToTop()));
  }

  private scrollContainerToTop(): void {
    setTimeout(() => {
      if (this.publicStatementContainer) this.publicStatementContainer.nativeElement.scrollTop = 0;
    });
  }

  private _listenAllowSkip(): void {
    this.recommendations = window.location.pathname.includes('recommendations');
    this.changes.detectChanges();
    this.sub.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.recommendations = window.location.pathname.includes('recommendations');
        this.changes.detectChanges();
      }),
    );
  }
}
