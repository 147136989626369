<div class="flex flex-row">
  <df-tactics-list-sidebar
    [modalList]="true"
    [step]="step"
    class="hidden md:inline-flex"
  ></df-tactics-list-sidebar>
  <df-tactics-tiles
    [modalList]="true"
    [step]="step"
    [toCopy]="toCopy"
    [funnel]="funnel"
  ></df-tactics-tiles>
</div>
