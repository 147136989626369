import { Injectable } from '@angular/core';
import { AuthGraphqlService } from '@modules/authorization/shared/graphql/auth.graphql.service';
import { FetchResult } from '@apollo/client/core';
import { IsMarketplaceStripeAccountVerifiedQuery } from '@modules/authorization/shared/graphql/queries/is-marketplace-stripe-account-verified.query.generated';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceStripeAccountService {
  private readonly _verified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly verified$: Observable<boolean> = this._verified$.asObservable();
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  constructor(private readonly authGraphqlService: AuthGraphqlService) {}

  checkStripeAccount(): void {
    this._loading$.next(true);
    this.authGraphqlService.isMarketplaceStripeAccountVerified().subscribe(
      (val: FetchResult<IsMarketplaceStripeAccountVerifiedQuery>) =>
        this._verified$.next(!!val.data?.isMarketplaceStripeAccountVerified),
      () => this._verified$.next(false),
      () => this._loading$.next(false),
    );
  }
}
