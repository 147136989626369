import { Component, Input } from '@angular/core';
import { Step } from '@shared/models/step.model';

@Component({
  selector: 'df-statement-tactics-step-header',
  templateUrl: './statement-tactics-step-header.component.html',
  styleUrls: ['./statement-tactics-step-header.component.scss'],
})
export class StatementTacticsStepHeaderComponent {
  @Input() step!: Step;
}
