<tui-avatar
  [class]="class"
  [text]="text"
  [size]="size"
  [avatarUrl]="avatarUrl"
  [autoColor]="true"
  [rounded]="true"
></tui-avatar>
<tui-badge
  class="border-4 border-white"
  *ngIf="showBadge"
  size="l"
  status="custom"
  value="PRO"
  [hoverable]="false"
></tui-badge>
