<ng-container *ngIf="tactic">
  <ng-container *ngIf="!tactic.root">
    <div
      *ngIf="!tactic.isPaid"
      [tuiHint]="!userService.User?.isPublic ? marketplaceTooltip : null"
      tuiHintDirection="bottom-left"
      [tuiHintShowDelay]="0"
    >
      <ng-template #marketplaceTooltip>
        <div class="text-center">
          <span [innerHTML]="'Tactics.Manage.payedInfo' | translate"></span>
          <span
            class="underline cursor-pointer"
            (click)="n.go('account')"
            >{{ 'UserSettings.Account settings' | translate }}</span
          >
        </div>
      </ng-template>
      <button
        tuiButton
        class="mr-2 lg:mr-4 tui-text-05 font-semibold"
        size="l"
        appearance="outline"
        type="button"
        (click)="sellOnMarketplace()"
        [disabled]="!userService.User?.isPublic"
      >
        <svg-icon
          [src]="'/assets/img/ic-budget.svg'"
          class="lg:mr-2"
          [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
        ></svg-icon>
        <span class="hidden lg:block">{{ 'Tactics.Manage.Sell on marketplace' | translate }}</span>
      </button>
    </div>
  </ng-container>
</ng-container>
