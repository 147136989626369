import { SwiperOptions } from 'swiper';

export const swiperConfig: SwiperOptions = {
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 1,
    },
    950: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
    1350: {
      slidesPerView: 4,
    },
  },
  pagination: { clickable: true },
  direction: 'horizontal',
  centeredSlidesBounds: true,
  slidesPerView: 4,
  edgeSwipeDetection: 'prevent',
};
