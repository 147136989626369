<div class="w-full h-full flex flex-nowrap justify-start items-stretch">
  <!-- img -->
  <div class="hidden lg:flex w-96 tui-support-26-bg justify-center items-center p-4">
    <img
      src="assets/img/ai-illustration.svg"
      class="w-60 h-60"
      alt="credits"
    />
  </div>
  <div class="flex flex-col justify-center items-stretch md:pl-10 md:py-12 md:pr-16 flex-grow">
    <div class="flex flex-col justify-center items-start">
      <!-- header -->
      <h4
        class="mb-3 text-xl tui-text-03 font-semibold text-left"
        [innerHtml]="'Funnels.Recommendations.Dialogs.Intelligent tactics recommendations' | translate"
      ></h4>
      <!-- text -->
      <p
        class="text-base font-medium tui-text-05 text-left mb-7"
        [innerHtml]="'Funnels.Recommendations.Dialogs.We have chosen the most relevant tactics for you' | translate"
      ></p>

      <!-- advantage -->
      <div
        *ngFor="let advantage of advantages"
        class="advantage flex justify-start items-center border border-solid tui-support-27-border p-4 rounded-2xl mt-2"
      >
        <div class="flex justify-center items-center cheeseburger-text-light p-3 tui-support-27-bg rounded-lg">
          <svg-icon
            [svgStyle]="{ width: '1.25rem', height: '1.25rem' }"
            src="/assets/img/_ic_check_circle.svg"
          >
          </svg-icon>
        </div>
        <p class="tui-text-body-l tui-text-03 font-semibold ml-3 whitespace-pre-line">
          {{ advantage | translate }}
        </p>
      </div>
      <!-- button -->
      <button
        tuiButton
        appearance="primary"
        size="l"
        class="mt-7 self-start"
        (click)="close()"
      >
        <span>{{ 'Funnels.Recommendations.Dialogs.Show tactics recommendations' | translate }}</span>
      </button>
    </div>
  </div>
</div>
