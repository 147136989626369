<div class="statement new-statement-background">
  <header
    class="statement__header flex items-center px-4 py-2"
    [ngClass]="{ 'tui-accent-08 !important': recommendations }"
  >
    <svg-icon
      *ngIf="!userService.User?.isSemrushPlan()"
      [src]="xPartnerService.partner.SYSTEM_LOGO_PATH"
    ></svg-icon>
    <div *ngIf="!userService.User?.isSemrushPlan()">
      <img
        alt=""
        *ngIf="stepNr"
        class="hidden sm:block absolute top-0 right-0"
        src="assets/img/statement_bg.svg"
      />
    </div>
  </header>
  <div
    class="statement__content"
    #publicStatementContainer
  >
    <router-outlet></router-outlet>
  </div>
</div>
