import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-funnel-manage-flows-search',
  templateUrl: './funnel-manage-flows-search.component.html',
  styleUrls: ['./funnel-manage-flows-search.component.scss'],
})
export class FunnelManageFlowsSearchComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();

  @Input() search!: UntypedFormControl;
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.listenSearch();
  }

  listenSearch() {
    const sub = this.search.valueChanges.subscribe((value: string) => {
      this.onSearch.emit(value);
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
