import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialog } from '@taiga-ui/cdk';

@Component({
  selector: 'df-confirm-start-trial-modal',
  templateUrl: './confirm-start-trial-modal.component.html',
  styleUrls: ['./confirm-start-trial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmStartTrialModalComponent {
  get planToBuy(): SubscriptionPlanOutputGraphql | null {
    return this.context.data?.plan ?? null;
  }

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<{ data: { plan: SubscriptionPlanOutputGraphql } }, boolean>,
  ) {}

  cancel(): void {
    this.context.completeWith(false);
  }

  confirm(): void {
    this.context.completeWith(true);
  }
}
