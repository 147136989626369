import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tactic } from '@shared/models/tactic.model';

@Component({
  selector: 'df-tactic-price-badge',
  templateUrl: './tactic-price-badge.component.html',
  styleUrls: ['./tactic-price-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticPriceBadgeComponent {
  @Input() tactic: Tactic | undefined | null;
  @Input() small = false;
}
