import { Component, ElementRef, Input, ViewChild } from '@angular/core';
// import { dia, ui, shapes, util } from '@clientio/rappid';
import { FunnelTactic } from '@shared/models/funnel-tactic.model';
import { Step } from '@shared/models/step.model';

@Component({
  selector: 'df-jointjs-canvas',
  templateUrl: './jointjs-canvas.component.html',
  styleUrls: ['./jointjs-canvas.component.scss'],
})
export class JointjsCanvasComponent {
  @ViewChild('canvas') canvas!: ElementRef;
  @Input() tactics!: FunnelTactic[];
  @Input() step!: Step;
}
