/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, OnInit } from '@angular/core';
import { TuiFilterComponent } from '@taiga-ui/kit';
import { Subscription } from 'rxjs';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';

@Directive({
  selector: '[tuiIFilterIdentityMatcher]',
})
export class TuiIFilterIdentityMatcherDirective implements OnInit {
  sub: Subscription = new Subscription();

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  constructor(
    private elRef: ElementRef,
    private tuiFilter: TuiFilterComponent<any>,
  ) {}

  ngOnInit() {
    this.tuiFilter.identityMatcher = this.identityMatcher;
  }
}
