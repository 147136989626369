<div
  *ngIf="done"
  class="ml-8 absolute z-10 -top-5 text-lg flex justify-center items-center -rotate-3 tui-accent-07-bg tui-base-01 w-auto px-3 h-10 font-semibold rounded-2xl"
>
  {{ 'Statement.DONE!' | translate }}
</div>
<div
  class="h-70 tui-base-01-bg flex flex-col z-10 rounded-2xl mt-2 pb-2 sm:mt-0 card-border"
  [ngClass]="{ done: done }"
>
  <div class="flex justify-between tui-text-03">
    <div class="w-36 h-36 m-4">
      <img
        [src]="imageSrc"
        alt="step icon"
        class="w-full h-full"
      />
    </div>
    <p
      class="tui-text-04 mt-5 mr-4 text-xl"
      [ngClass]="{ 'tui-accent-07': done }"
    >
      {{ number }}
    </p>
  </div>
  <p
    class="tui-text-03 m-4 xl:text-2xl lg:text-lg text-xl font-semibold sm:w-5/6"
    [innerHTML]="instruction"
  ></p>
</div>
