<div class="my-funnel__container flex flex-row p-4 md:p-8 relative">
  <df-full-list-loader *ngIf="loading"></df-full-list-loader>
  <swiper
    [config]="swiperOptions"
    [spaceBetween]="0"
    class="w-full"
  >
    <ng-template
      swiperSlide
      *ngFor="let step of steps"
    >
      <tui-island class="flex flex-col items-start justify-start ml-2 mr-2 column">
        <div class="flex-row flex items-center justify-start mb-8">
          <svg-icon
            [src]="'/assets/img/ic-' + step?.type + '-fill.svg'"
            class="mr-4"
            [svgStyle]="{ width: '3rem', height: '3rem' }"
          ></svg-icon>
          <div class="flex flex-col">
            <div class="tui-text-01 tui-text_h6 font-medium">
              {{ step.name }}
            </div>
            <div class="tui-text-02 mt-1">
              {{ 'Tactics.count tactics' | translate: { count: step?.tactics?.length } }}
            </div>
          </div>
        </div>
        <div class="column__tiles flex flex-col w-full md:justify-center items-center">
          <df-tactic-tile
            *ngFor="let tactic of step?.tactics"
            [tactic]="tactic"
            [noLeftRightMargin]="true"
            [isRemoveFromFunnelAction]="true"
            [noShadow]="true"
            [colorClass]="step.type"
            class="mb-2"
          ></df-tactic-tile>
          <button
            size="l"
            tuiButton
            type="button"
            class="block button-add"
            appearance="outline"
            [icon]="iconAddToFunnel"
            (click)="openTacticsModalList(step)"
          ></button>
          <ng-template #iconAddToFunnel>
            <svg-icon [src]="'/assets/img/ic-add.svg'"></svg-icon>
          </ng-template>
        </div>
      </tui-island>
    </ng-template>
  </swiper>
</div>
