import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TutorialVideoComponent } from '@modules/funnels/modules/funnel-manage/pages/funnel-panel/components/funnel-manage/components/tutorial-video/tutorial-video.component';
import { UserService } from '@shared/services/user.service';
import { TuiDialogService } from '@taiga-ui/core';
import { Router } from '@angular/router';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { NavigateService } from '@core/routes/services/navigate.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  tutorialEnded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private router: Router,
    private funnelGraphqlService: FunnelGraphqlService,
    public n: NavigateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  openTutorial(onDemand?: boolean) {
    if (onDemand) {
      this._openDialog();
    } else {
      if (!this.userService.User?.tutorialEnded) {
        this._saveTutorialEnded();
        this._openDialogForStatement();
      } else {
        this._onEnd();
      }
    }
  }

  private _openDialog() {
    this._tutorialDialog().subscribe();
  }

  private _openDialogForStatement() {
    this._tutorialDialog()
      .subscribe()
      .add(() => this._onEnd());
  }

  private _saveTutorialEnded() {
    this.userService.endTutorial(true).subscribe(() => {
      const currentUser = this.userService.User;
      currentUser!.tutorialEnded = true;
      this.userService.User = currentUser;
    });
  }

  private _tutorialDialog() {
    return this.dialogService.open<number>(new PolymorpheusComponent(TutorialVideoComponent, this.injector), {
      size: 'l',
      closeable: true,
    });
  }

  private _onEnd() {
    this.router.navigate([{ outlets: { statement: null } }]).then(() => {
      this.n.go('funnels/f/d/:id/manage', {
        id: this.userService.User?.contextFunnel.id,
      });
      this.funnelGraphqlService.refreshEmitter.emit(true);
    });
  }
}
