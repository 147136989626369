<h3 class="tui-text_h4 text-center w-full mb-6">
  {{ 'Pricing.start trial' | translate }}
</h3>
<p class="w-full tui-text-03 mb-10 text-center px-10">
  <span
    [innerHTML]="
      'Pricing.We upgraded the platform so now you’ll get access to all of Digital First’s features absolutely free!'
        | translate
    "
  ></span>
</p>
<div class="w-full flex justify-center items-center gap-y-2 gap-x-3">
  <button
    tuiButton
    appearance="outline"
    type="button"
    size="l"
    (click)="cancel()"
    [disabled]="loading"
  >
    {{ 'cancel' | translate }}
  </button>
  <button
    tuiButton
    appearance="primary"
    type="button"
    size="l"
    (click)="confirm()"
    [showLoader]="loading"
  >
    {{ 'Pricing.Start your 3-days trial' | translate }}
  </button>
</div>
