<div class="flex items-center justify-between w-full mb-2">
  <div class="flex items-center self-center flex-wrap w-5/6">
    <svg-icon
      *ngFor="let item of tactic.funnelSteps"
      [src]="'/assets/img/ic-' + item.type + '-fill.svg'"
      class="self-center mr-2"
    ></svg-icon>
  </div>
  <div class="flex justify-end w-1/6">
    <tui-checkbox
      (change)="watchTactics(tactic, step.id)"
      size="l"
      [formControl]="tacticFormControl"
    >
    </tui-checkbox>
  </div>
</div>
<div
  *ngIf="tactic?.effects?.length"
  class="w-min font-bold rounded-md tui-base-02 flex items-center justify-center h-6 pl-2 pr-2 uppercase tui-text_body-xs tui-text-02"
>
  {{ tactic!.effects[0].name }}
</div>
<h1 class="tui-text-01 tui-text_h5 line-clamp-3 break-words mt-2 mb-8">
  {{ tactic.name }}
</h1>
