import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TacticsFiltersService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-filters.service';
import { TuiIdentityMatcher } from '@taiga-ui/cdk';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Subscription } from 'rxjs';
import { TacticsListService } from '@modules/tactics/modules/tactics-list/shared/services/tactics-list.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ListEvent, ListEventType } from '@shared/services/base-list.service';

@Component({
  selector: 'df-tactics-list-categories',
  templateUrl: './tactics-list-categories.component.html',
  styleUrls: ['./tactics-list-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticsListCategoriesComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();

  readonly identityMatcher: TuiIdentityMatcher<{ id: number; name: string }> = (obj1, obj2) => obj1.id === obj2.id;

  get categories(): IFilter<any>[] | undefined {
    return this.tacticFiltersService.filterFormGroup.get('categories')?.value;
  }

  constructor(
    public tacticFiltersService: TacticsFiltersService,
    public tacticsListService: TacticsListService,
    private changes: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.listenReadUrlParams();
    this.listenRouteChange();
    this.listenFormChanges();
  }

  listenReadUrlParams() {
    const sub = this.tacticsListService.emitter.subscribe((event: ListEvent) => {
      if (event.type === ListEventType.READ_URL_PARAMS) {
        this.changes.detectChanges();
      }
    });
    this.sub.add(sub);
  }

  listenRouteChange() {
    const sub = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.changes.detectChanges());
    this.sub.add(sub);
  }

  listenFormChanges() {
    this.sub.add(this.tacticFiltersService.filterFormGroup.valueChanges.subscribe(() => this.changes.detectChanges()));
  }

  removeCategory(id: number, e) {
    e.preventDefault();
    e.stopPropagation();
    let categories = this.categories;
    categories = categories?.filter((c) => c.id !== id);
    this.tacticFiltersService.filterFormGroup.get('categories')?.setValue(categories);
    this.changes.detectChanges();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
