export class RecommendationAi {
  step?: RecommendationAiStep;
  usedInFunnel!: false;
  order!: number;
  tactic!: RecommendationAiTactic;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}

export class RecommendationAiTactic {
  id!: number;
  name!: string;
  rate!: number;
  // owner!: RecommendationAiOwner;
  funnelSteps!: Array<RecommendationAiStep>;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}

export class RecommendationAiStep {
  id!: number;
  name!: string;
  type!: string;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}

export class RecommendationAiOwner {
  id!: number;
  firstName!: string;
  lastName!: string;

  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
  }
}
