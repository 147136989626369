import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'df-statement-after-form-filled',
  templateUrl: './statement-after-form-filled.component.html',
  styleUrls: ['./statement-after-form-filled.component.scss'],
})
export class StatementAfterFormFilledComponent implements OnInit, OnDestroy {
  Config = Config;

  constructor(
    public n: NavigateService,
    public statementService: StatementService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.statementService.stepNr.next(0);
  }

  onEnd() {
    this.router.navigate([{ outlets: { statement: null } }]);
    if (!this.statementService.startPreviousUrl) {
      setTimeout(() => {
        this.n.go(Config.MAIN_VIEW);
      }, 10);
    }
  }

  ngOnDestroy() {
    this.statementService.startPreviousUrl = '';
  }
}
