<df-full-list-loader *ngIf="statementService.loadingTactics"></df-full-list-loader>
<div class="container flex items-center justify-start flex-col pb-4 sm:pb-8 lg:pb-16">
  <div class="flex px-8 md:px-2 mt-5 lg:mt-12 mb-4 lg:mb-10 w-full">
    <div class="hidden lg:block mr-6">
      <svg-icon [src]="'/assets/img/statement-recommendations-rocket.svg'"></svg-icon>
    </div>
    <div class="flex flex-col">
      <h1 class="tui-primary mb-3 tui-text_body-m-6 text-2xl md:text-4xl lg:text-6xl mt-0">
        {{ 'Statement.Your growth tactics are ready!' | translate }}
      </h1>
      <p class="font-semibold text-lg tui-text-03">
        {{ "Statement.What's next?" | translate }}
      </p>
    </div>
  </div>
  <div
    class="p-4 sm:px-4 w-full flex flex-col sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:gap-3 lg:gap-0 lg:flex lg:flex-row justify-start mb-8"
  >
    <df-tutorial-card
      class="lg:mr-5"
      [done]="true"
      [imageSrc]="'assets/img/statement-recommendations-01card.png'"
      [number]="'01'"
      [instruction]="'Statement.Complete your business statement' | translate"
    ></df-tutorial-card>
    <df-tutorial-card
      class="lg:mt-8 lg:mr-5"
      [imageSrc]="'assets/img/statement-recommendations-02card.png'"
      [number]="'02'"
      [instruction]="'Statement.Select tactics based on your goals' | translate"
    ></df-tutorial-card>
    <df-tutorial-card
      class="lg:mt-16 lg:mr-5"
      [imageSrc]="'assets/img/statement-recommendations-03card.png'"
      [number]="'03'"
      [instruction]="'Statement.Add tactics to your funnel' | translate"
    ></df-tutorial-card>
    <df-tutorial-card
      class="lg:mt-24 lg:mr-5"
      [imageSrc]="'assets/img/statement-recommendations-04card.png'"
      [number]="'04'"
      [instruction]="'Statement.Manage your funnel and start growing your business!' | translate"
    ></df-tutorial-card>
    <div class="hidden xl:block relative top-72 ml-auto z-100">
      <svg-icon
        class="absolute"
        [src]="'/assets/img/statement-recommendations-bended-arrow.svg'"
      ></svg-icon>
    </div>
  </div>
  <div
    *ngIf="!statementService.missingPermission"
    class="w-11/12 lg:w-full recommended-shadow rounded-2xl mx-3 xl:mx-0 mb-10 p-4 sm:p-10 flex flex-col tui-base-01-bg"
  >
    <div class="flex flex-col align-stretch md:flex-row md:justify-between mb-8 text-center md:text-left">
      <div class="md:w-3/4 flex flex-col items-center md:items-start">
        <h2 class="tui-text-03 tui-text_h2 font-semibold">
          {{ 'Statement.Start growing with the best tactics!' | translate }}
        </h2>
        <p class="tui-text-05 tui-text_body-l-2">
          {{ 'Statement.We recommend you this tactics based on statement.' | translate }}
        </p>
      </div>
      <div
        class="flex flex-col items-center justify-end md:items-end mt-8 md:mt-0 md:flex-shrink-0 md:ml-6"
        *ngIf="numberOfTactics"
      >
        <button
          class="self-center"
          tuiButton
          type="button"
          [showLoader]="loading"
          appearance="primary"
          (click)="openConfirmation()"
        >
          <svg-icon
            [src]="'/assets/img/funnel-add.svg'"
            class="mr-1"
          ></svg-icon>
          <span class="font-semibold">{{
            tactics.length !== numberOfTactics
              ? ('Statement.Add selected tactics' | translate)
              : ('Statement.Add all tactics' | translate)
          }}</span>
        </button>
        <a
          (click)="tutorialService.openTutorial()"
          class="tui-text_body-m-1 tui-text-02 mt-2 md:mt-3 cursor-pointer"
        >
          {{ 'Statement.Skip and go to the panel' | translate }}
        </a>
      </div>
    </div>
    <df-statement-tactics
      (emitTacticSet)="loadTacticsToFunnel($event)"
      (numberOfRecommendedTactics)="loadNumberOfTactics($event)"
    >
    </df-statement-tactics>
  </div>

  <div
    *ngIf="statementService.missingPermission"
    class="w-full relative rounded-2xl tui-base-01-bg overflow-hidden"
  >
    <div class="absolute top-0 left-0 right-0 p-3 lg:p-5 xl:p-14">
      <df-statement-tactics
        (emitTacticSet)="loadTacticsToFunnel($event)"
        (numberOfRecommendedTactics)="loadNumberOfTactics($event)"
      >
      </df-statement-tactics>
    </div>

    <div
      class="no-access-placeholder flex-col flex items-center md:items-start w-full relative pt-44 lg:pt-52 px-8 md:px-20 xl:px-24 pb-8 lg:pb-20"
    >
      <h2
        class="tui-text-03 tui-text_h5 md:text-2xl md:leading-normal lg:text-4xl lg:leading-snug mb-6 font-semibold max-w-sm lg:max-w-2xl text-center md:text-left"
      >
        {{ 'Statement.Reach access to tactics recommendations with our' | translate }}
        <a
          class="block tui-primary underline tui-text_h5 md:text-2xl md:leading-normal lg:text-4xl lg:leading-snug font-semibold"
          (click)="goToPackets()"
          >{{ 'Statement.Pricing Plans' | translate }}</a
        >
      </h2>
      <div class="flex justify-start items-end">
        <button
          size="l"
          tuiButton
          type="submit"
          (click)="goToPackets()"
          class="tui-accent-08 tui-primary font-bold"
        >
          {{ 'Statement.View Pricing List' | translate }}
        </button>
        <span class="mx-6 tui-text-02 text-base">{{ 'or' | translate }}</span>
        <button
          size="l"
          tuiButton
          type="submit"
          (click)="skipPackets()"
          appearance="outline"
        >
          <span class="tui-text-02">{{ 'Statement.Skip and go to funnel' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
