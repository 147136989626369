<div
  *ngIf="tactic"
  class="flex justify-start items-stretch"
>
  <div
    class="tui-accent-13-bg rounded-lg font-bold uppercase tui-primary py-1 px-1.5 text-sm"
    [ngClass]="{ 'text-xs p-0.5': small }"
    *ngIf="!tactic.root"
  >
    <ng-container *ngIf="tactic?.isPaid; else free">
      {{ tactic!.price! | price | currency: tactic.currency?.toUpperCase() : 'symbol' : '0.2-2' }}
    </ng-container>
    <ng-template #free>{{ 'free' | translate }}</ng-template>
  </div>

  <div
    class="ml-1 tui-base-02 rounded-lg font-bold uppercase tui-text-02 py-1 px-1.5 text-sm"
    [ngClass]="{ 'text-xs p-0.5': small }"
    *ngIf="tactic.purchased"
  >
    {{ 'Tactics.purchased' | translate }}
  </div>

  <div
    class="tui-base-02 rounded-lg font-bold uppercase tui-text-02 py-1 px-1.5 text-sm"
    [ngClass]="{ 'text-xs p-0.5': small }"
    *ngIf="tactic.root"
  >
    {{ 'Tactics.from library' | translate }}
  </div>
</div>
