import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StatementGraphqlService } from '@modules/statement/shared/services/statement-graphql.service';
import { UserService } from '@shared/services/user.service';
import { Recommendation } from '@shared/models/recommendations.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavigateService } from '@core/routes/services/navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@core/services/snackbar.service';
import { FunnelGraphqlService } from '@modules/funnels/shared/services/funnel-graphql.service';
import { PricingService } from '@modules/pricing/shared/services/pricing.service';
import { CreateManyFunnelTacticInputGraphql, PermissionType } from '@modules/graphql/graphql-types';
import { ActivatedRoute } from '@angular/router';
import { FunnelManageService } from '@modules/funnels/modules/funnel-manage/shared/services/funnel-manage.service';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { forkJoin, Observable, of } from 'rxjs';
import { FetchResult } from '@apollo/client/core';
import { CreateManyFunnelsTacticsMutation } from '@modules/tactics/shared/graphql/mutations/create-many-funnel-tactics.mutation.generated';
import { AbstractFunnelSubpageComponent } from '@shared/abstracts/funnel-subpage.component.abstract';
import { ItacticRecommsData } from '@modules/funnels/modules/funnel-manage/shared/interfaces/tactic-recomm-data.interface';
import { RecommendationAi } from '@shared/models/recommendations-ai.model';

@Component({
  selector: 'df-funnel-recommendations-ai',
  templateUrl: './funnel-recommendations-ai.component.html',
  styleUrls: ['./funnel-recommendations-ai.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelRecommendationsAiComponent extends AbstractFunnelSubpageComponent implements OnInit {
  funnelId: number | null = null;
  loading = false;
  recommendationsAccess = false;

  constructor(
    private readonly funnelGraphqlService: FunnelGraphqlService,
    private readonly statementGraphqlService: StatementGraphqlService,
    public readonly statementService: StatementService,
    private readonly userService: UserService,
    private readonly changes: ChangeDetectorRef,
    private readonly n: NavigateService,
    private readonly t: TranslateService,
    private readonly s: SnackbarService,
    private readonly pricingService: PricingService,
    protected readonly route: ActivatedRoute,
    protected readonly funnelManageService: FunnelManageService,
  ) {
    super(route, funnelManageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.funnelId = this.userService.User?.contextFunnel?.id!;
    this.recommendationsAccess = this.userService.User?.hasAccess(PermissionType.TacticsRecommendations) ?? false;
    this.fetchRecommendationsAi();
    this.changes.detectChanges();
  }

  fetchRecommendationsAi() {
    const sub = this.route.params
      .pipe(
        map((params) => {
          this.loading = true;
          this.changes.detectChanges();
          return this.statementService.fetchStatement(params['id']);
        }),
        switchMap((statement) => {
          this.changes.detectChanges();
          return statement;
        }),
        filter((statement) => {
          statement ? null : (this.loading = false);
          this.changes.detectChanges();

          console.log('>>loading', this.loading);
          return !!statement;
        }),
        switchMap((statement) => {
          this.changes.detectChanges();
          return this.statementService.fetchRecommendationsAi();
        }),
        switchMap((recomms) => {
          this.statementService.recommendations = this.prepareTacticRecomms(recomms);
          console.log('>>>>>> statement  recomms', this.statementService.recommendations);
          this.loading = false;
          this.changes.detectChanges();

          return of(null);
        }),
      )
      .subscribe();
    this.sub.add(sub);
  }

  prepareTacticRecomms(recomms: ItacticRecommsData) {
    const recommendations = new Array<RecommendationAi>();
    recomms.tactics.forEach((recomm) => {
      const recommObj = new RecommendationAi({
        step: {
          id: recomm.step.id,
          name: recomm.step.name,
          type: recomm.step.type,
        },
        usedInFunnel: recomm.used_in_funnel,
        order: recomm.order,
        tactic: {
          id: recomm.tactic.id,
          name: recomm.tactic.name,
          rate: recomm.tactic.rate,
          // owner:{}
          funnelSteps: recomm.tactic.funnel_steps,
        },
      });

      recommendations.push(recommObj);
    });
    return recommendations.sort((a: RecommendationAi, b: RecommendationAi) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  }

  addRecommendationsToFunnel(recommendations: Recommendation[]): void {
    this.loading = recommendations.length > 0;
    this.changes.detectChanges();

    const inputs: CreateManyFunnelTacticInputGraphql[] = this.mapRecommendationsToSteps(recommendations);
    const chunk = 10;
    const obs: Observable<FetchResult<CreateManyFunnelsTacticsMutation>>[] = [];
    for (let i = 0; i < inputs.length; i += chunk) {
      obs.push(this.statementGraphqlService.createManyFunnelsTactics(inputs.slice(i, i + chunk), this.funnelId!));
    }

    this.pricingService
      .checkPricing(PermissionType.FunnelManagement, this.userService.User?.funnelsCount)
      .pipe(switchMap(() => forkJoin(obs)))
      .subscribe(
        () => {
          this.n.go('funnels/f/d/:id/manage', { id: this.funnelId! });
          this.funnelGraphqlService.refreshEmitter.emit(true);
          this.statementService.recommendations?.forEach(
            (recommendation) =>
              (recommendation.usedInFunnel = recommendations.some((r) => r.tactic.id === recommendation.tactic.id)
                ? true
                : recommendation.usedInFunnel),
          );
        },
        () =>
          this.s.error(
            this.t.instant('Statement.Something went wrong during adding recommended tactics. Please try again.'),
          ),
        () => {
          this.loading = false;
          this.changes.detectChanges();
        },
      );
  }

  private mapRecommendationsToSteps(recommendations: Recommendation[]): CreateManyFunnelTacticInputGraphql[] {
    const steps: { [key: number]: number } = {};
    return recommendations
      .sort((a, b) => (a.tactic.funnelSteps.length < b.tactic.funnelSteps.length ? -1 : 1))
      .map((r) => {
        let step: number = r.step?.id || 0;
        steps[step] = steps[step] === undefined ? 0 : steps[step];
        if (r.tactic.funnelSteps.length > 1) {
          r.tactic.funnelSteps.forEach((s) => {
            steps[s.id] = steps[s.id] === undefined ? 0 : steps[s.id];
            step = steps[s.id] < steps[step] ? s.id : step;
          });
        }
        steps[step] += 1;
        return {
          tacticId: r.tactic.id,
          stepId: step,
          position: 0,
        };
      });
  }
}
