import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'df-parameter-box',
  templateUrl: './parameter-box.component.html',
  styleUrls: ['./parameter-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterBoxComponent {
  @Input() icon = '';
  @Input() label = '';
  @Input() value?: string = '';
  @Input() valueColorClass = '';
}
