<li
  class="packets__info-list__access"
  (mouseover)="onHoverPermission.emit(pricePermission.id)"
  [attr.data-access-id]="pricePermission.id"
>
  <span
    *ngIf="pricePermission.planPermissions[plan.id]"
    class="check check--true"
    [ngClass]="{ 'check--upcoming': upcoming }"
  >
    <tui-svg
      class="icon"
      src="tuiIconCheckLarge"
      *ngIf="!pricePermission.planPermissions[plan.id]['hasLimit']"
    ></tui-svg>

    <df-packets-permission-limit
      [pricePermission]="pricePermission"
      [plan]="plan"
    ></df-packets-permission-limit>
  </span>

  <span
    *ngIf="!pricePermission.planPermissions[plan.id]"
    class="check check--false"
    [ngClass]="{ 'check--upcoming': upcoming }"
  >
    <tui-svg
      class="icon"
      src="tuiIconCloseLarge"
    ></tui-svg>
  </span>
</li>
