import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigateService } from '@core/routes/services/navigate.service';
import { Config } from '@shared/configs/config';
import { StatementService } from '@modules/statement/shared/services/statement.service';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'df-statement-after-skipped',
  templateUrl: './statement-after-skipped.component.html',
  styleUrls: ['./statement-after-skipped.component.scss'],
})
export class StatementAfterSkippedComponent implements OnInit, OnDestroy {
  Config = Config;

  constructor(
    public n: NavigateService,
    public statementService: StatementService,
    private router: Router,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.statementService.stepNr.next(0);
  }

  onEnd() {
    this.router.navigate([{ outlets: { statement: null } }]).then(() => this.n.go('/tactics/list/all'));
  }

  ngOnDestroy() {
    this.statementService.startPreviousUrl = '';
  }
}
