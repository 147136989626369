import { DialogTallyRecStmtServiceService } from './dialog-tally-rec-stmt-service.service';
import { Component, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'df-dialog-tally-rec-stmt',
  templateUrl: './dialog-tally-rec-stmt.component.html',
  styleUrls: ['./dialog-tally-rec-stmt.component.scss'],
})
export class DialogTallyRecStmtComponent implements OnInit {
  Config = Config;
  urlPart = '';

  constructor(private dts: DialogTallyRecStmtServiceService) {}

  ngOnInit(): void {
    this.urlPart = this.dts.tallyCustomUrl;
  }
}
