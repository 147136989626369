<div class="flex items-center">
  <div class="rounded-full w-16 h-16 min-w-16 {{ step.type }} mr-4 flex justify-center items-center tui-base-01">
    <svg-icon
      [src]="'/assets/img/ic-' + step.type + '.svg'"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
    ></svg-icon>
  </div>
  <div class="w-3/5 text-left flex flex-col items-start tui-text_body-xs">
    <h4 class="{{ step.type }}-text uppercase font-bold">
      {{ step.name }}
    </h4>
    <div class="flex items-start mt-1">
      <h5 class="uppercase tui-text-01 font-bold">{{ step.tactics.length }} {{ 'Statement.Tactics' | translate }}</h5>
    </div>
  </div>
</div>
