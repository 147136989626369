<div class="flex flex-nowrap justify-evenly items-center flex-grow-0 w-full h-full py-0.5 relative">
  <tui-input
    icon="tuiIconSearchLarge"
    iconAlign="left"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldLabelOutside]="true"
    [formControl]="search"
    class="flex-grow search"
  >
    {{ 'Tactics.List.Search by key words' | translate }}

    <input tuiTextfield />
  </tui-input>
  <div class="hidden xl:block w-7/12 2xl:w-6/12 mx-3 flex-shrink-0">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </div>

  <button
    class="xl:hidden mx-1.5"
    tuiIconButton
    appearance="outline"
    size="l"
    icon="tuiIconDragLarge"
    (click)="filtersOpened = !filtersOpened"
  ></button>
  <div
    class="xl:hidden tablet-filters-bar"
    [class.opened]="filtersOpened"
  >
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </div>

  <ng-template #filters>
    <form
      [formGroup]="form"
      class="w-full grid grid-cols-8 grid-rows-1 gap-x-3"
    >
      <tui-multi-select
        class="col-span-3"
        formControlName="categories"
        [editable]="false"
        [valueContent]="selectedInArray('categories')"
        [tuiTextfieldCleaner]="true"
      >
        {{ 'Tactics.List.Category' | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          tuiMultiSelectGroup
          [items]="filtersService.categories"
          [itemContent]="stringifyFilter"
        >
        </tui-data-list-wrapper>
      </tui-multi-select>

      <tui-multi-select
        class="col-span-3"
        formControlName="step"
        [editable]="false"
        [valueContent]="selectedInArray('step')"
        [tuiTextfieldCleaner]="true"
      >
        {{ 'Tactics.List.Funnel step' | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          tuiMultiSelectGroup
          [items]="filtersService.steps"
          [itemContent]="stepContent"
        >
        </tui-data-list-wrapper>
      </tui-multi-select>

      <ng-template
        #stepContent
        let-step
      >
        <div class="flex items-center justify-start">
          <svg-icon
            [src]="'/assets/img/ic-' + step?.object?.type + '.svg'"
            class="mr-2"
            [svgStyle]="{ width: '1rem', height: '1rem' }"
          ></svg-icon>
          <span>{{ step.name }}</span>
        </div>
      </ng-template>

      <tui-filter
        class="pro-filter col-span-2"
        formControlName="pro"
        size="l"
        [content]="proContent"
        [items]="filtersService.proFilters"
        [class.pointer-events-none]="listService.loading || listService.loadingNextPage || listService.loadingPrevPage"
      ></tui-filter>
      <ng-template
        let-item
        #proContent
      >
        <div class="flex justify-center items-center tui-accent-02 font-medium text-sm">
          <svg-icon
            [src]="'/assets/img/ic_pro_star.svg'"
            class="mr-2"
          ></svg-icon>
          {{ item.name }}
        </div>
      </ng-template>
    </form>
  </ng-template>

  <button
    tuiIconButton
    appearance="outline"
    size="l"
    icon="tuiIconHeartLarge"
    [routerLink]="n.getPath('tactics/list/favourite')"
    routerLinkActive="active-hidden"
  ></button>

  <button
    tuiIconButton
    appearance="outline"
    size="l"
    [icon]="filled"
    [routerLink]="n.getPath('/tactics/list/all')"
    [class.active-hidden]="!isFavouriteShowing()"
  ></button>

  <ng-template #filled>
    <svg-icon
      [src]="'/assets/img/ic_heart_alt_filled.svg'"
      [svgStyle]="{ width: '1.5rem', height: '1.5rem' }"
    ></svg-icon>
  </ng-template>
</div>
