<div
  class="credits-cost-container"
  *ngIf="costBadgeService.creditsPrice$ | async"
>
  <img
    src="assets/img/credits-badge.png"
    alt="coin"
  />
  <span class="tui-text_body-m-7">
    {{ costBadgeService.creditsPrice$ | async }}
  </span>
</div>
