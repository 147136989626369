import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionPlanOutputGraphql } from '@modules/graphql/graphql-types';
import { Permission } from '@shared/models/permission.model';

@Component({
  selector: 'df-packets-permission',
  templateUrl: './packets-permission.component.html',
  styleUrls: ['./packets-permission.component.scss'],
})
export class PacketsPermissionComponent {
  @Input() pricePermission!: Permission;
  @Input() plan!: SubscriptionPlanOutputGraphql;
  @Input() upcoming = false;

  @Output() onHoverPermission: EventEmitter<number> = new EventEmitter<number>();
}
