import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IVerticalTab } from '@shared/modules/ui/components/vertical-tabs/vertical-tab.interface';

@Component({
  selector: 'df-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalTabsComponent {
  @Input() tabs: IVerticalTab[] = [];
  @Input() size: 'xs' | 's' | 'm' | 'l' = 'l';
  @Input() isRouterLink = true;

  @Output() onTabChange: EventEmitter<IVerticalTab> = new EventEmitter<IVerticalTab>();

  @ViewChild('verticalUnderline', { static: true })
  verticalUnderline!: ElementRef;

  onIndexChange(event) {
    this.verticalUnderline.nativeElement.style.top = event * 4 + 'rem';
  }
}
