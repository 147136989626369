import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  PaidTacticConditions,
  PaidTacticValidationService,
} from '@modules/tactics/modules/tactic-settings/shared/services/paid-tactic-validation.service';
import { AbstractSubscriptionComponent } from '@shared/abstracts/subscription.component.abstract';

@Component({
  selector: 'df-paid-tactics-checks',
  templateUrl: './paid-tactics-checks.component.html',
  styleUrls: ['./paid-tactics-checks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaidTacticsChecksComponent extends AbstractSubscriptionComponent implements OnInit {
  @Input() errorMode = false;
  readonly conditions = Object.values(PaidTacticConditions);

  constructor(
    public readonly paidTacticValidationService: PaidTacticValidationService,
    private readonly changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub.add(
      this.paidTacticValidationService.tacticValidationForm.statusChanges.subscribe(() => this.changes.detectChanges()),
    );
  }
}
